<template>
  <div class="modal fade" id="user-profile-edit-details-modal" tabindex="-1" aria-labelledby="userProfileEditDetailsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row align-items-center">
            <div class="col-auto">
              <h2 class="modal-title" id="team-preview-modal-label">Edit your profile</h2>
            </div>
            <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
          </div>
        </div>
        <div class="modal-body">
          <Form v-slot="{ errors }" @submit="EditEmployee" ref="editEmployeeForm">
            <div class="row">
              <div class="col-7">
                <div class="form-group">
                  <label class="form-label"> <span class="required">Name</span> </label>
                  <Field type="text" name="employeeName" class="form-control" placeholder="Employee name" v-model="employeeDetails.Name" rules="required|min: 3" />
                  <div v-if="errors.employeeName" class="fv-plugins-message-container invalid-feedback">Name requires minimum 3 characters.</div>
                </div>

                <div class="form-group">
                  <label class="form-label"> <span class="required">Email</span> </label>
                  <Field type="text" class="form-control" placeholder="Employee email" name="email" v-model="employeeDetails.Email" rules="required|email" />
                  <div v-if="errors.email" class="fv-plugins-message-container invalid-feedback">Enter valid email.</div>
                </div>
              </div>
              <div class="col text-center">
                <img v-if="urlFile" :src="urlFile" class="mw-100px mh-100px d-block m-auto mt-10" />
                <img v-else :src="require('@/assets/images/user.svg')" class="mw-100px mh-100px d-block m-auto mt-10" />
                <label for="user-image" class="btn btn-gradient-success mt-10"> Change image </label>
                <input class="d-none" type="file" id="user-image" accept=".png, .jpg, .jpeg" @change="onFileChange" />
              </div>
            </div>

            <div class="d-flex flex-stack pt-10">
              <div>
                <button type="button" class="btn btn-light-dark me-3" data-bs-dismiss="modal">Close</button>
              </div>

              <div>
                <button class="btn btn-gradient-success">Save</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";

import { defineRule } from "vee-validate";
import { required, min } from "@vee-validate/rules";
import { Field, Form } from "vee-validate";
defineRule("required", required);
defineRule("min", min);

export default {
  name: "userProfileEditDetailsModal",
  props: ["employeeDetails", "rolesProp"],
  watch: {
    employeeDetails: function (newVal, oldVal) {
      this.urlFile = newVal.urlFile;
    },
  },
  emits: ["editEmployeeEmit"],
  components: {
    Field,
    Form,
  },
  data() {
    return {
      urlFile: null,
      roleForUser: [],
    };
  },
  methods: {
    onFileChange: function (e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.urlFile = rawImg;
      };
      reader.readAsDataURL(file);
      this.employeeDetails.ImageIsModify = true;
    },
    RemoveImage: function () {
      this.urlFile = null;
      this.employeeDetails.ImageIsModify = true;
    },
    ClearModal: function () {
      this.urlFile = null;
    },

    EditEmployee: function () {
      this.employeeDetails.Image = this.urlFile;
      const employeeData = {
        ...this.employeeDetails,
        Roles: [],
      };

      employeeData.Roles = this.employeeDetails?.Roles;

      if (employeeData?.Roles.length == undefined) {
        employeeData.Roles = [];
        employeeData.Roles.push(this.employeeDetails?.Roles);
      }

      this.$emit("editEmployeeEmit", employeeData);
    },
  },
  created() {},
};
</script>
