<template>
  <div class="card mb-8 mt-8 evaluation-summary-card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <h1>{{ evaluationDetails.Name }}</h1>
          <p>{{ evaluationDetails.Description }}</p>
        </div>
      </div>
      <hr />
      <div class="row align-items-center">
        <div class="col d-flex align-items-center">
          <img v-if="evaluationDetails.Scope" v-bind:src="require('@/assets/images/scopes-icons/' + evaluationDetails.Scope + '.png')" />
          <div>
            <small>Scope</small>
            <h5>{{ evaluationDetails.Scope }}</h5>
          </div>
        </div>
        <div class="col d-flex align-items-center">
          <img v-if="evaluationDetails.Target" v-bind:src="require('@/assets/images/scopes-icons/' + evaluationDetails.Target + '.png')" />
          <div>
            <small>Target</small>
            <h5>{{ evaluationDetails.Target }}</h5>
          </div>
        </div>
        <div class="col">
          <!-- <small>Team </small> -->
          <small>{{ evaluationDetails.Target }} </small>
          <h5>{{ evaluationDetails.EvaluationFor }}</h5>
        </div>
        <div class="col">
          <small>Period</small>
          <h5>{{ $utils.formatDate(evaluationDetails.StartDate) }} <span class="text-green">-</span> {{ $utils.formatDate(evaluationDetails.EndDate) }}</h5>
        </div>

        <div class="col d-flex align-items-center">
          <i class="fa-light fa-image-user fa-3x me-3 text-green"></i>
          <div>
            <small>Self assessment</small>
            <h5 v-if="evaluationDetails.SelfAssessment">Yes</h5>
            <h5 v-else>No</h5>
          </div>
        </div>
        <div class="col d-flex align-items-center">
          <i class="fa-light fa-image-user fa-3x me-3 text-green"></i>
          <div>
            <small>Answers</small>
            <h5>{{ averageDetails.MembersAnswered }}/{{ averageDetails.MembersAnswer }} Members</h5>
          </div>
        </div>
        <div v-if="evaluationDetails.SelfAssessment" class="col d-flex align-items-center">
          <i class="fa-light fa-image-user fa-3x me-3 text-green"></i>
          <div>
            <small>Your score</small>
            <h5>{{ averageDetails.LeaderScore }}</h5>
          </div>
        </div>
        <div class="col d-flex align-items-center">
          <div class="symbol symbol-40px me-2 symbol-circle">
            <i class="fa-regular fa-circle-exclamation fa-3x me-2  text-green" :style="{ color: 'red' }"></i>
          </div>
          <div>
            <small>Anonymous</small>
            <h5 v-if="evaluationDetails.IsAnonim == true">YES</h5>
            <h5 v-else>NO</h5>
          </div>
        </div>
        <div class="col d-flex align-items-center">
          <i class="fa-light fa-image-user fa-3x me-3 text-green"></i>
          <div>
            <small>Members Avg.</small>
            <h5 v-if="evaluationDetails.SelfAssessment == false || averageDetails.LeaderSigned == true">{{ averageDetails.MembersAvarage }}</h5>
            <h5 v-else>You don't submitted</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "leader-evaluation-information-component",
  props: ["evaluationDetails", "averageDetails"],
};
</script>
