<template>
  <div class="text-center p-20">
    <img src="@/assets/images/not-found.png">
    <span class="d-block fs-18px mt-11">{{ text }}</span>
  </div>
</template>

<script>
export default {
  Name: "not-found-component",
  props: {
    text: String,
  },
};
</script>
