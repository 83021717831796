<template>
  <div class="introduction-section pb-0">
    <div class="bg"></div>
    <div class="container text-center">
      <div class="title">
        <div class="text-black">Performanța echipei</div>
        <div class="text-green">Evoluția si caracteristicile definitorii</div>
      </div>
      <div class="description-section text-light-gray">
        Soluția noastră fluidizează procesul de evaluarea al angajaților, <br />
        în același timp oferind posibilitatea de a urmări performanța acestora de-a lungul timpului.
      </div>
      <router-link :to="{ name: 'Login' }" target="_blank">
        <button type="button" class="btn btn-gradient-green">Încearcă gratuit</button>
      </router-link>
      <img class="img-compare-people" src="../../../src/assets/images/landing-page/ro/compara-persoanele.png" />
    </div>
  </div>

  <div class="about-experteval-section">
    <div class="container-fluid">
      <div class="row">
        <div class="align-items-center col-xl d-flex order-1 order-xl-0">
          <img src="../../../src/assets/images/landing-page/ro/admin-acasa.png" />
        </div>
        <div class="col-1 d-none d-xl-block"></div>
        <div class="col-xl order-0 order-xl-0 mb-10 mb-xl-0">
          <div class="title-section mt-0 mt-xl-12">Despre ExpertEval</div>
          <div class="content-section mt-12">
            <div>
              Gestionarea fără efort a utilizatorilor, echipelor, grupurilor și proiectelor cu o interfață prietenoasă și intuitivă. Adaugă și elimină cu ușurință membri, atribuie evaluări și
              vizualizează rapoartele de progres pentru persoane și echipe.
            </div>
            <!-- <div class="pt-6">
              Credeți că evaluarea manuală a oamenilor este o pierdere de timp, resurse și bani? Încercați ExpertEval astăzi și începeți să valorificați întregul potențial al echipei dumneavoastră.
            </div> -->
            <router-link :to="{ name: 'Login' }" target="_blank">
              <button type="button" class="btn btn-white mt-12" style="width: 243px">Încearcă gratuit</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="benefits-section">
    <div class="container-lg text-center">
      <div class="title-section">Beneficii</div>
      <div class="description-section text-light-gray">
        Aplicația noastră de evaluare oferă 6 beneficii-cheie pentru afaceri, <br />
        de la optimizarea evaluării angajaților până la creșterea productivității și implicării echipei.
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4 mb-6" v-for="(item, index) in benefits" :key="index">
          <div class="card h-100">
            <div class="card-body text-center">
              <div class="box">
                <img :src="require(`../../../src/assets/images/landing-page/icons/${item.Icon}`)" />
              </div>
              <p>{{ item.Description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="features-section">
    <div class="bg"></div>
    <div class="container-xxl">
      <div class="row align-items-center pt-20 pb-20">
        <div class="col-xxl">
          <div class="title-section mt-0">Caracteristici</div>
          <div class="description-section mb-12">
            Aplicația noastră de evaluare oferă 6 beneficii cheie pentru întreprinderi, de la simplificarea evaluărilor <br />
            angajaților până la stimularea productivității și angajamentului echipei
          </div>
          <div class="row justify-content-center justify-content-xxl-start" style="margin-left: -10px">
            <div class="col-sm-6 mb-4" v-for="(item, index) in features" :key="index">
              <div class="card h-100">
                <div class="card-body">{{ item }}</div>
              </div>
            </div>
            <div class="col-xxl-6 col-sm-4 col-6">
              <button type="button" class="btn btn-white mt-16 w-100">Abonamente</button>
            </div>
          </div>
        </div>
        <div class="col-1 d-none d-xxl-block"></div>
        <div class="col-xxl-auto pe-xxl-0 mt-20 mt-xxl-0">
          <img src="../../../src/assets/images/landing-page/ro/profilul-utilizatorului.png" />
        </div>
      </div>
    </div>
  </div>

  <div class="how-it-works-section text-center">
    <div class="container">
      <div class="title-section">Cum funcționează</div>
      <div class="description-section text-light-gray">
        Descoperă puterea aplicației <br />
        și experimentează pe pagina demo acum!
      </div>
      <router-link :to="{ name: 'Login' }" target="_blank">
        <button type="button" class="btn btn-gradient-green">Încearcă gratuit</button>
      </router-link>
    </div>
  </div>

  <div class="available-plans-section">
    <div class="bg"></div>
    <img src="../../../src/assets/images/landing-page/ro/clienti-eval_echipe-raspunde.png" />
    <div class="container-xxl">
      <div class="title-section mb-16">Planuri disponibile</div>
      <LandingPagePlanCards />
    </div>
  </div>

  <LandingPageQuestions />

  <LandingPageContact />

  <LandingPageFooter :dynamicHeight="true" />
</template>

<script>
import LandingPageQuestions from "@/components/Questions/LandingPageQuestionsComponent.vue";
import LandingPagePlanCards from "@/components/Cards/LandingPagePlanCardsComponent.vue";
import LandingPageContact from "@/components/Forms/LandingPageContactComponent.vue";
import LandingPageFooter from "@/components/Footer/LandingPageFooter.vue";

export default {
  name: "HomeLandingView",
  components: { LandingPageQuestions, LandingPagePlanCards, LandingPageContact, LandingPageFooter },
  data() {
    return {
      benefits: [
        {
          Icon: "profile.png",
          Description: "Economisiți timp prin automatizarea procesului de evaluare",
        },
        {
          Icon: "team.png",
          Description: "Îmbunătățiți performanța și productivitatea echipei",
        },
        {
          Icon: "hr.png",
          Description: "Generați informații valoroase pentru resurse umane și management ",
        },
        {
          Icon: "boost-employee.png",
          Description: "Stimulați angajamentul și moralul angajaților",
        },
        {
          Icon: "people.png",
          Description: "Identificați punctele tari și punctele slabe pentru a informa planurile de dezvoltare",
        },
        {
          Icon: "eval.png",
          Description: "Personalizați evaluările în funcție de nevoile companiei",
        },
      ],
      features: [
        "Șabloane de evaluare personalizabile pentru diferite roluri și obiective",
        "Interfață ușor de utilizat pentru crearea și gestionarea evaluărilor",
        "Posibilitate de a atribui evaluările unor anumiți membri ai echipei sau lideri",
        "Mai multe planuri de tarifare pentru a se potrivi diferitelor dimensiuni și bugete ale echipelor",
        "Integrare cu alte programe și instrumente de resurse umane",
        "Analize încorporate pentru a urmări performanța",
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
/*AVAILABLE PLANS*/

.available-plans-section .title-section {
  color: white;
}

.available-plans-section .bg {
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.15);
  background-image: url("../../assets/images/landing-page/available-plans-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 72%;
  right: 0;
  left: 0;
  top: 331px;
  z-index: -1;
}

.available-plans-section {
  position: relative;
  text-align: center;
  margin-bottom: 160px;
}
@media only screen and (max-width: 992px) {
  .available-plans-section .bg {
    top: 0;
    height: 100%;
  }
  .available-plans-section .row {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 1730px) {
  .available-plans-section img {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .available-plans-section .card {
    height: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .available-plans-section .title-section {
    margin-top: 160px;
  }
}

/*HOW IT WORKS*/
.how-it-works-section .container {
  background-image: url("../../assets/images/landing-page/how-it-works-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.how-it-works-section {
  margin-bottom: 160px;
  position: relative;
}

/*FEATURES*/
.features-section img {
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: block;
  margin: auto;
}
.features-section .card {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  min-height: 89px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}
.features-section .description-section {
  line-height: 26px;
}
.features-section .bg {
  background-size: cover;
  z-index: -1;
  background-image: url("../../assets/images/landing-page/features-bg.png");
  background-repeat: no-repeat;
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0px 20px 20px 0px;
  position: absolute;
  display: inline-block;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
}
.features-section {
  margin-bottom: 200px;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 795px;
}
@media (max-width: 1100px) {
  .features-section img {
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .features-section .bg {
    max-width: 80vw;
  }
}
@media (min-width: 1400px) and (max-width: 1700px) {
  .features-section img {
    max-width: 55vw;
  }
  .features-section .container-xxl {
    max-width: 100%;
  }
}
@media (min-width: 1700px) {
  .features-section .container-xxl {
    max-width: 1800px;
  }
}

/*BENEFITS*/
.benefits-section::after {
  background-size: cover;
  z-index: -1;
  background-image: url("../../assets/images/landing-page/square-benefits-bg.png");
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
}

.benefits-section .card .card-body .box .img {
  max-width: 90px;
}

.benefits-section .card .card-body .box {
  height: 121px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.benefits-section .card .card-body p {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(73, 80, 87, 1);
  max-width: 190px;
  margin: auto;
}

.benefits-section .row {
  margin-top: 62px;
}

.benefits-section {
  margin-top: 50px;
}
.benefits-section {
  position: relative;
  min-height: 820px;
  margin-bottom: 70px;
}
@media (min-width: 576px) {
  .benefits-section {
    margin-top: 315px;
    margin-bottom: 269px;
  }
}

/*ABOUT EXPERT EVAL*/

.about-experteval-section {
  position: relative;
  color: white;
  background: linear-gradient(87.6deg, #188a94 0%, #006871 100%);
  background-repeat: no-repeat;
  padding-bottom: 50px;
}
.about-experteval-section .row {
  padding-top: 40px;
}

@media (max-width: 1200px) {
  .about-experteval-section img {
    max-width: 90vw;
    display: block;
    margin: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .about-experteval-section img {
    max-width: 45vw;
  }
}
@media (min-width: 1350px) {
  .about-experteval-section {
    max-height: 42vw;
    padding-bottom: 0px;
    height: 100%;
  }
}
@media (min-width: 1620px) {
  .about-experteval-section {
    max-height: 598px;
  }
}
@media (min-width: 1600px) {
  .about-experteval-section .container-fluid {
    max-width: 1800px;
  }
}
@media (min-width: 1200px) {
  .about-experteval-section .row {
    padding-top: 120px;
  }
}

.about-experteval-section .content-section {
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
}
.about-experteval-section img {
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

/*INTRODUCTION*/
.introduction-section .img-compare-people {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.15);
  margin-top: 64px;
  width: 100%;
}
</style>
