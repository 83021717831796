<template>
  <div class="modal fade" id="edit-short-evaluation-modal" tabindex="-1" aria-labelledby="edit-short-evaluation-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="Save" ref="editEvaluationForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="edit-short-evaluation-modal-label">Edit evaluation</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="label">Name</label>
              <textarea :disabled="true" v-model="evaluationDetails.Name" class="form-control" rows="2" placeholder="Evaluation name"></textarea>
            </div>
            <div class="form-group">
              <label class="label">Description</label>
              <textarea :disabled="true" v-model="evaluationDetails.Description" class="form-control" rows="2" placeholder="Evaluation description"></textarea>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="label">Start date</label>
                  <div class="input-group mb-5">
                    <Field v-model="evaluationDetails.StartDate" name="startDate" v-slot="{ field }" rules="required">
                      <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                      <Datepicker
                        v-bind="field"
                        v-model="evaluationDetails.StartDate"
                        class="form-control custom-datepicker"
                        placeholder="Select start date"
                        :enableTimePicker="false"
                        autoApply
                        name="Start date"
                        format="dd-MM-yyyy"
                      ></Datepicker>
                    </Field>
                    <div v-if="errors.startDate" class="fv-plugins-message-container invalid-feedback">Start date is required.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="label">End Date</label>
                <div class="input-group mb-5">
                  <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                  <Field v-model="evaluationDetails.EndDate" name="endDate" v-slot="{ field }" :rules="{ end_date_rule: [evaluationDetails.StartDate, evaluationDetails.EndDate] }">
                    <Datepicker
                      v-bind="field"
                      v-model="evaluationDetails.EndDate"
                      class="form-control custom-datepicker"
                      placeholder="Select end date"
                      :enableTimePicker="false"
                      autoApply
                      name="Start date"
                      format="dd-MM-yyyy"
                    ></Datepicker>
                  </Field>
                  <div v-if="errors.endDate" class="fv-plugins-message-container invalid-feedback">{{ errors.endDate }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer flex-stack">
            <button type="button" data-bs-dismiss="modal" class="border btn btn-white">Close</button>
            <button type="Submit" class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineRule } from "vee-validate";
import { Field, Form } from "vee-validate";

export default {
  name: "edit-short-evaluation-modal-component",
  components: { Field, Form },
  props: ["evaluationDetails"],
  emits: ["update"],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    Save: function () {
       this.$emit("update");
    },
  },
};
</script>

<style scoped>
.fa-square:hover:before {
  content: "\f14a";
  color: #ddd !important;
  transition: 0.1s;
}

.card-body img {
  width: 30px;
}

.card {
  cursor: pointer;
  border: 1px solid white;
}

.scroll-table {
  max-height: 490px;
  overflow: auto;
}

.card:hover {
  border: 1px solid #4dc4b3;
  background: #f6fcfb;
}

.fa-square,
.fa-square-check {
  cursor: pointer;
}

.selected-scope {
  border: 1px solid #4dc4b3;
  background: #f6fcfb;
}

.card-body {
  padding: 2rem 5px;
}
</style>
