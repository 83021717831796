import moment from "moment";
let utils = {
  formatDate: function (date) {
    if (date != null) {
      return moment(date).format("DD-MM-YYYY");
    } else return;
  },


  formatDateNiceText: function (date) {
    if (date != null) {
      return moment(date).format("Do MMM YYYY");
    } else return;
  },

  removeTimeFromDate: function (date) {
    if (date != null) {
      var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0, 0)
      return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000)
    } else return;
  },


  IsEvaluationCompleted: function (evaluationContent) {
    var status = true;
    var nrOfQuestions = 0;
    var firstQuestion = '';
    evaluationContent.Categories.forEach((category) => {
      category.Questions.forEach((question) => {
        nrOfQuestions++;
        if (question.Grade == 0 && !firstQuestion) {
          firstQuestion = document.getElementById(
            "question-" + question.AnswerId
          );

          firstQuestion.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          status = false;
        }
      });
    });

    if (!status) {
      setTimeout(
        () =>
        // eslint-disable-next-line no-undef
        Swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: "warning",
          title: "Missing answers!",
          text: "You have to answer to the all questions.",
        }),
        nrOfQuestions * 150
      );
    }

    return status;
  },

  DragScrollByElementId(elementId) {
    const container = document.getElementById(elementId);

    let isDown = false;
    let startX;
    let scrollLeft;

    container.addEventListener("mousedown", (e) => {
      isDown = true;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    });

    container.addEventListener("mouseleave", () => {
      isDown = false;
    });

    container.addEventListener("mouseup", () => {
      isDown = false;
    });

    container.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 3; // Adjust the scrolling speed
      container.scrollLeft = scrollLeft - walk;
    });
  },

};
export default utils;