<template>
  <!-- normal -->
  <header class="align-items-center">
    <nav class="justify-content-between navbar navbar-expand-md p-4 shadow-sm">
      <div class="d-flex align-items-center">
        <img height="40" src="@/assets/images/logos/logo.png" />
      </div>
      <ul class="navbar-nav">
        <li class="nav-item me-4" v-if="$store.getUser?.UserRoles?.includes('Admin')">
          <div class="dropdown">
            <span class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> Templates <i class="fa-solid fa-chevron-down fs-6 ms-2"></i> </span>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <router-link class="dropdown-item" :to="{ name: 'EvaluationsTemplatesView' }"> Evaluations </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'CategoriesTemplatesView' }"> Categories </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'QuestionsTemplateView' }"> Questions </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item me-4" v-if="$store.getUser?.UserRoles?.includes('Admin')">
          <div class="dropdown">
            <span class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> Settings <i class="fa-solid fa-chevron-down fs-6 ms-2"></i> </span>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <router-link class="dropdown-item" :to="{ name: 'EmployeesView' }"> Employees </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'TeamsView' }"> Teams </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'CustomersView' }"> Customers </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'ProjectsView' }"> Projects </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'EvaluationGroupView' }"> Groups </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="m-0 nav-item me-4" v-if="AccessToEvaluations()">
          <router-link :to="{ name: 'ActiveEvaluationsView' }">Evaluations </router-link>
        </li>

        <li class="m-0 nav-item me-4">
          <router-link :to="{ name: 'UserActiveEvaluationsView' }">My evaluations </router-link>
        </li>

        <li class="m-0 nav-item me-4">
          <router-link :to="{ name: 'CompareView' }">Compare </router-link>
        </li>
      </ul>

      <div class="form-inline">
        <div class="dropdown">
          <span class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="symbol symbol-35px symbol-circle me-4" data-bs-toggle="tooltip" data-kt-initialized="1">
              <img alt="Pic" :src="GetImgUrl($store.getUser?.Image)" />
            </div>
            <h5 class="m-0 d-inline-block" v-if="$store.getUser">Hello, {{ ShowFirstWordFromUserName($store.getUser?.EmployeeName) }}</h5>
            <span v-if="!$store.getUser">Not logged in</span> <i class="fa-solid fa-chevron-down fs-6 ms-2"></i>
          </span>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <router-link :to="{ name: 'UserProfileView' }" class="dropdown-item"><i class="fal fa-user me-4"></i> My profile</router-link>
            </li>
            <li>
              <span class="dropdown-item" v-on:click="SignOut()"> <i class="fal fa-right-from-bracket me-4"></i> Sign Out </span>
            </li>
            <li>
              <a class="dropdown-item external" href=" /Documentation.pdf"> <i class="fal t fa-file me-4"></i> Documentation </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped>
header {
  border-bottom: 1px solid #dcdcdc !important;
  background: #f3f5f6;
  position: sticky;
  top: 0px;
  z-index: 50;
}

.nav-item .router-link-exact-active {
  color: #188a94;
}

.router-link-exact-active {
  color: #188a94;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

nav {
  margin-left: auto;
}

nav ul {
  list-style: none;
}

ul li {
  display: inline-flex;
  margin-left: 1rem;
}
</style>

<script>
import auth from "@/utils/auth.js";
import store from "@/utils/vuex.js";

export default {
  name: "AppHeaderComponent",
  data() {
    return {
      windowWidth: window.innerWidth,
      showMobileMenu: false,
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  methods: {
    AccessToEvaluations: function () {
      var roles = ["Admin", "TeamLeader", "ProjectManager", "GroupLeader"];
      return this.$store.getUser?.UserRoles?.some((r) => roles.includes(r));
    },
    ClearLocalStorage: function () {
      localStorage.removeItem("allEvaluationsProp");
      localStorage.removeItem("myEvaluationsProp");
      localStorage.removeItem("activeEvaluationsProp");
      localStorage.removeItem("detailsEvaluationsProp");
      localStorage.removeItem("membersProp");
    },

    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    ShowFirstWordFromUserName: function (userName) {
      if (userName && this.windowWidth < 1450) return userName.substring(0, userName.indexOf(" "));
      return userName;
    },

    SignOut: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post("api/Auth/Logout")
        .then(() => {
          auth.setJwtToken(null);
          auth.setRefreshToken(null);
          store.dispatch("removeLoggedUser").then(() => {
            this.$router.push("/Login");
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          this.$swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: "error",
            title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
};
</script>
