<template>
  <div class="container-sm text-center">
    <div class="title-section">Întrebări puse frecvent</div>
    <div class="description-section text-light-gray">Ai o întrebare? Suntem aici să te ajutăm.</div>
    <div class="accordion" id="accordionFaq">
      <div class="accordion-item" v-for="(item, index) in faqs" :key="index">
        <h2 class="accordion-header" :id="'heading-' + index">
          <button
            class="accordion-button"
            :class="{ collapsed: index !== 0 }"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse-' + index"
            aria-expanded="false"
            :aria-controls="'collapse-' + index"
          >
            {{ item.Question }}
          </button>
        </h2>
        <div :id="'collapse-' + index" class="accordion-collapse collapse" :class="{ show: index == 0 }" :aria-labelledby="'heading-' + index" data-bs-parent="#accordionFaq">
          <div class="accordion-body">
            {{ item.Answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPageQuestionsComponent",
  data() {
    return {
      faqs: [
        {
          Question: "Ce este o aplicație de evaluare?",
          Answer: "O aplicație de evaluare este un instrument conceput pentru a evalua și analiza performanțele angajaților și a liderilor.",
        },
        {
          Question: "Pot personaliza șabloanele de evaluare?",
          Answer: "Da, șabloanele de evaluare se pot personaliza cumpărând abonamentul PRO sau PREMIUM.",
        },
        {
          Question: "Pot mai mulți utilizatori să acceseze aplicația de evaluare?",
          Answer: "Da, în funcție de pachetul selectat. Un număr nelimitat de accesări este disponibil în pachetul PREMIUM.",
        },
        {
          Question: "Pot să vadă managerii răspunsurile la evaluări făcute de angajați?",
          Answer: "Da, doar managerii pot să aibă acces la evaluările efectuate de angajați.",
        },
        {
          Question: "Ce fel de instrumente de analiză a datelor oferă aplicația?",
          Answer: "Analize în timp real a rezultatelor evaluărilor, sondajelor și a datelor.",
        },
        // {
        //   Question: "Cum protejează aplicația confidențialitatea evaluărilor angajaților?",
        //   Answer: "-",
        // },
        {
          Question: "Aplicația de evaluare este ușor de folosit?",
          Answer: "Da, aplicație de evaluare are o interfață prietenoasă și intuitivă, nu îngreunează procesul de evaluare, ci simplifică volumul de muncă.",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.accordion-item {
  background-color: transparent;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
}

/*FAQ ACCORDION*/
.accordion-button {
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: rgba(73, 80, 87, 1);
}

.accordion-body {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding: 0px 24px 24px 50px;
  text-align: left;
  color: rgba(108, 117, 125, 1);
}

.accordion-button::after {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23181C32'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
</style>
