<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Evaluations</a></li>
        <li class="breadcrumb-item">
          <a href="#">{{ evaluationDetails.Name }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page"><a href="#" class="active" @click="$router.go(-1)">Comparisation</a></li>
      </ol>
    </nav>

    <div class="row align-items-end">
      <div class="col-auto col-md">
        <h1 class="title">{{ evaluationDetails.Name }}</h1>
        <p>{{ evaluationDetails.Description }}</p>
      </div>
      <div class="col-auto" :style="{ width: scoreColumnWidth + 32 + 'px' }">
        <div class="bg-white rounded p-4">
          <label class="mb-4 fw-bold label">Member</label>
          <VueMultiselect v-model="selectedMember" track-by="EmployeeId" :allowEmpty="false" :options="evaluationMembers" :placeholder="'Search for member'" label="Name" :searchable="true" :internalSearch="false" @select="SelectMember"></VueMultiselect>
        </div>
      </div>
    </div>

    <div class="card mb-8 mt-8 evaluation-summary-card">
      <div class="card-body">
        <div class="row">
          <div class="col-auto col-md mt-1 mb-1">
            <div class="row align-items-center">
              <div class="col d-flex align-items-center">
                <img src="@/assets/images/networking.png" />
                <div>
                  <small>Scope</small>
                  <h5>{{ evaluationDetails.Scope }}</h5>
                </div>
              </div>
              <div class="col-auto col-md mt-1 mb-1 d-flex align-items-center">
                <img src="@/assets/images/networking.png" />
                <div>
                  <small>Team</small>
                  <h5>{{ evaluationDetails.Target }}</h5>
                </div>
              </div>
              <div class="col-auto col-md mt-1 mb-1">
                <small>Target</small>
                <h5>{{ evaluationDetails.EvaluationFor }}</h5>
              </div>
              <div class="col-auto col-md mt-1 mb-1">
                <small>Period</small>
                <h6>{{ $utils.formatDate(evaluationDetails.StartDate) }} - {{ $utils.formatDate(evaluationDetails.EndDate) }}
                </h6>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <!-- <div v-if="evaluated.EvaluationScore > 0" class="col-auto" :style="{ width: scoreColumnWidth + 'px' }"> -->
              <div v-if="evaluated.EvaluationScore > 0" class="col mt-1 mb-1">
                <div class="big-score-bage bg-dark-green">
                  <h1>{{ evaluated.EvaluationScore }}</h1>
                  <span>{{ evaluated.WhoEvaluatedName }}</span>
                </div>
              </div>
              <!-- <div v-if="whoEvaluate.EvaluationScore > 0" class="col-auto" :style="{ width: scoreColumnWidth + 'px' }"> -->
              <div v-if="whoEvaluate.EvaluationScore > 0" class="col mt-1 mb-1">
                <div class="big-score-bage bg-orange">
                  <h1>{{ whoEvaluate.EvaluationScore }}</h1>
                  <span>{{ whoEvaluate.WhoEvaluatedName }}</span>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="text-end mb-3" v-if="evaluationDetails.TargetId == 2 && whoEvaluate.IsSigned == true"> <button type="button" class="btn btn-light-success btn-sm me-2" v-on:click="Download()">Download</button>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in answers">
          <div class="row">
            <div class="col">
              <h1>{{ category.CategoryName }}</h1>
              <p>
                {{ category.CategoryDescription }}
              </p>
            </div>
            <div v-if="evaluated.IsSigned == true" class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 text-gray-700">{{ evaluated.WhoEvaluatedName }} score</h5>
                </div>
                <div class="col-auto">
                  <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-dark-green me-2"></i> {{ category.EvaluatedCategoryScore
                      }}</span>
                </div>
              </div>
            </div>
            <div v-if="whoEvaluate.IsSigned == true" class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 text-gray-700">{{ whoEvaluate.WhoEvaluatedName }} score</h5>
                </div>
                <div class="col-auto">
                  <span class="bg-gray-200 p-2 rounded text-warning fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-warning me-2"></i> {{ category.CategoryScore }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center" v-bind:key="questionIndex" v-for="(question, questionIndex) in category.Questions">
            <div class="col">
              <question-timeline-details-component :question="question"></question-timeline-details-component>
            </div>
            <div v-if="evaluated.IsSigned == true" class="col-auto questions-score-div">
              <!-- <preview-score-component :question="question"></preview-score-component> -->
              <div class="question-score-list preview-list">
                <div class="question-score" v-bind:key="index" v-for="index in question.MaxScore" v-bind:class="{ active: index == question.EvaluatedGrade }">{{ index }}</div>
              </div>
            </div>
            <div v-if="whoEvaluate.IsSigned == true" class="col-auto">
              <!-- <preview-score-component :question="question"></preview-score-component> -->
              <div class="question-score-list preview-list">
                <div class="question-score" v-bind:key="index" v-for="index in question.MaxScore" v-bind:class="{ active: index == question.Grade }">{{ index }}</div>
              </div>
            </div>
          </div>

          <hr class="mt-15 mb-15" />
        </div>
      </div>

      <div class="card-px text-center pt-15 pb-15" v-if="evaluated.IsSigned != true && whoEvaluate.IsSigned != true">
        <p class="text-gray-400 fs-4 fw-bold py-7">If you want to see the results, at least one employee must complete the
          evaluation!</p>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewScoreComponent from "@/components/Common/Score/PreviewScoreComponent.vue";
import QuestionTimelineDetailsComponent from "@/components/Questions/QuestionTimelineDetailsComponent.vue";
import { saveAs } from "file-saver";
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
export default {
  name: "AnswersComparisonView",
  components: {
    PreviewScoreComponent,
    QuestionTimelineDetailsComponent,
  },

  data() {
    return {
      scoreColumnWidth: 320,
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
        Scope: null,
      },
      evaluated: {
        IsSigned: null,
        Categories: [],
        EvaluationScore: 0,
      },
      whoEvaluate: {
        IsSigned: null,
        Categories: [],
        EvaluationScore: 0,
      },
      answers: {},
      selectedMember: null,
      evaluationMembers: [],
      evaluationDetails: {},
    };
  },

  methods: {
    Download: async function () {
      store.dispatch("changeLoaderStatus", true);
      var query = new URLSearchParams({
        employeeId: this.$route.params.employeeId,
        evaluationPlanId: this.$route.params.evalId,
      });
      await this.$axios
        .get("api/ExportData/exportEvaluationResult?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          fetch("data:application/doc;base64," + response.data.ArrayByte)
            .then(function (resp) {
              return resp.blob();
            })
            .then(function (blob) {
              saveAs(blob, response.data.FileName + ".doc");
            });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    SelectMember: function (selected) {
      if (
        this.evaluationDetails.TargetId == 3 ||
        this.evaluationDetails.TargetId == 5
      ) {
        this.$router
          .push(
            "/evaluation/" +
              this.$route.params.evalId +
              "/" +
              this.$route.params.employeeId +
              "/" +
              selected.EmployeeId +
              "/answers/comparison"
          )
          .then(function () {
            window.location.reload();
          });
      }
      if (
        this.evaluationDetails.TargetId == 2 ||
        this.evaluationDetails.TargetId == 4
      ) {
        this.$router
          .push(
            "/evaluation/" +
              this.$route.params.evalId +
              "/" +
              selected.EmployeeId +
              "/" +
              this.$route.params.evaluatedBy +
              "/answers/comparison"
          )
          .then(function () {
            window.location.reload();
          });
      }
    },
    PrepareAnswersList: function () {
      if (!this.whoEvaluate.IsSigned && this.evaluated.IsSigned) {
        this.answers = this.evaluated.Categories.map((item) => {
          const newQuestions = item.Questions.map((question) => {
            return {
              ...question,
              EvaluatedGrade: question.Grade,
            };
          });

          return {
            ...item,
            Questions: newQuestions,
            EvaluatedCategoryScore: item.CategoryScore,
          };
        });
        return;
      }
      if (this.evaluated.IsSigned) {
        this.answers = this.whoEvaluate.Categories.map((item) => {
          //assign evaluated questionGrade to whoEvaluate
          const newQuestions = item.Questions.map((question) => {
            return {
              ...question,
              //if evaluated employee has completed the eval
              //we search his response in evaluated answers
              EvaluatedGrade: this.evaluated.Categories.find(
                (category) => category.CategoryId === item.CategoryId
              ).Questions.find((q) => q.QuestionId === question.QuestionId)
                .Grade,
            };
          });
          return {
            ...item,
            Questions: newQuestions,
            //assign evaluated categoryScore to whoEvaluate
            EvaluatedCategoryScore: this.evaluated.Categories.find(
              (category) => category.CategoryId === item.CategoryId
            ).CategoryScore,
          };
        });
      }
      if (this.whoEvaluate.IsSigned && !this.evaluated.IsSigned) {
        this.answers = this.whoEvaluate.Categories;
      }
    },
    GetEvaluatedAnswers: async function () {
      store.dispatch("changeLoaderStatus", true);
      var query = new URLSearchParams({
        employeeId: this.$route.params.employeeId,
        evaluationId: this.$route.params.evalId,
      });
      await this.$axios
        .get("api/Answers/getEvaluatedEmployeeAnswers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluated = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetWhoEvaluateAnswers: async function () {
      store.dispatch("changeLoaderStatus", true);
      var query = new URLSearchParams({
        employeeId: this.$route.params.employeeId,
        evaluatedBy: this.$route.params.evaluatedBy,
        evaluationId: this.$route.params.evalId,
      });
      await this.$axios
        .get("api/Answers/GetEmployeeWhoEvaluateAnswers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.whoEvaluate = response.data;

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationMembers: async function () {
      store.dispatch("changeLoaderStatus", true);
      if (this.filter.SearchText == null) {
        this.filter.SearchText = "";
      }
      var query = new URLSearchParams({
        evaluationId: this.$route.params.evalId,
        orderBy: this.filter.OrderBy,
      });
      await this.$axios
        .get("api/Employee/getMembersForReviewAnswers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluationMembers = response.data;
          if (
            this.evaluationDetails.TargetId == 3 ||
            this.evaluationDetails.TargetId == 5
          ) {
            this.selectedMember = this.evaluationMembers.find(
              (member) => member.EmployeeId == this.$route.params.evaluatedBy
            );
          }
          if (
            this.evaluationDetails.TargetId == 2 ||
            this.evaluationDetails.TargetId == 4
          ) {
            this.selectedMember = this.evaluationMembers.find(
              (member) => member.EmployeeId == this.$route.params.employeeId
            );
          }
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/EvaluationPlan/getEvaluationDetails/" +
            this.$route.params.evalId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  async created() {
    await this.GetEvaluationDetails();
    this.GetEvaluationMembers();
    await this.GetWhoEvaluateAnswers();
    await this.GetEvaluatedAnswers();
    this.PrepareAnswersList();
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  color: #188a94;
}
</style>
