<template>
  <div :class="[dynamicHeight ? 'bg-footer-dynamic-height' : 'bg-footer-fixed']" class="align-items-end d-flex justify-content-center">
    <div>
      <div class="container-xl text-start">
        <div class="row text-white align-items-baseline">
          <div class="col-12 col-xl mb-10 mb-xxl-0">
            <div class="expert-eval-title">ExpertEval</div>
            <div class="expert-eval-description">
              Folosind șabloanele personalizate și instrumentele eficiente <br />
              de analiză a datelor, managerii și angajații <br />
              pot evalua și urmări cu ușurință performanța.
            </div>
            <router-link :to="{ name: 'Login' }" target="_blank">
              <button type="button" class="btn btn-white text-uppercase" style="width: 243px; padding: 12px 16px 12px 16px !important">Încearcă gratuit</button>
            </router-link>
          </div>
          <div class="col-1 d-none d-xl-block"></div>
          <div class="col-md-6 col-xl-4">
            <div class="category-title">Pagini</div>
            <div class="row" style="margin-left: -8px">
              <div class="col-6 mb-3" v-for="(page, index) in pages" :key="index">
                <a :href="page.Link" target="_blank">{{ page.Name }}</a>
              </div>
            </div>
          </div>
          <div class="col-md col-xl-1 d-xl-none"></div>
          <div class="col-md-auto mt-10 mt-md-0">
            <div class="category-title">Contact</div>
            <div class="contacts ms-4">
              <div>
                <a href="tel:0040311333504">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                    />
                  </svg>

                  +40 31 133 3504
                </a>
              </div>
              <div>
                <a href="mailto:info@expertware.net">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  info@expertware.net
                </a>
              </div>
              <div>
                <a href="">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 13.4299C13.7231 13.4299 15.12 12.0331 15.12 10.3099C15.12 8.58681 13.7231 7.18994 12 7.18994C10.2769 7.18994 8.88 8.58681 8.88 10.3099C8.88 12.0331 10.2769 13.4299 12 13.4299Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                    <path
                      d="M3.62 8.49C5.59 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.39 20.54C5.63 17.88 2.47 13.57 3.62 8.49Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                  </svg>

                  Suceava, Romania
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-10 mb-10" />
        <div class="row text-white align-items-center">
          <div class="col-6 col-md order-2 order-md-0">
            <div class="row align-items-center">
              <div class="col col-md-2">
                <a href="tel:+40311333504">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="text-transparent-white">
                    <path
                      d="M11.05 14.95L9.2 16.8C8.81 17.19 8.19 17.19 7.79 16.81C7.68 16.7 7.57 16.6 7.46 16.49C6.43 15.45 5.5 14.36 4.67 13.22C3.85 12.08 3.19 10.94 2.71 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C10.83 13.32 10.94 13.42 11.04 13.52C11.44 13.91 11.45 14.55 11.05 14.95Z"
                      fill="currentColor"
                    />
                    <path
                      d="M21.97 18.33C21.97 18.61 21.92 18.9 21.82 19.18C21.79 19.26 21.76 19.34 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C19.39 21.62 19.38 21.63 19.37 21.63C18.78 21.87 18.14 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C10.36 19 9.96998 18.71 9.59998 18.4L12.87 15.13C13.15 15.34 13.4 15.5 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
              <div class="col col-md-2">
                <a href="mailto:info@expertware.net">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="text-transparent-white">
                    <path
                      d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
              <div class="col col-md-2">
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/Expertware/@47.6392364,26.2431502,17z/data=!3m1!4b1!4m6!3m5!1s0x4734fd5e981370f9:0x307f9a60c6ea1f84!8m2!3d47.6392364!4d26.2431502!16s%2Fg%2F11ny30f3zj?entry=ttu"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="text-transparent-white">
                    <g clip-path="url(#clip0_1203_111)">
                      <path
                        d="M20.62 8.45C19.57 3.83 15.54 1.75 12 1.75C12 1.75 12 1.75 11.99 1.75C8.45997 1.75 4.41997 3.82 3.36997 8.44C2.19997 13.6 5.35997 17.97 8.21997 20.72C9.27997 21.74 10.64 22.25 12 22.25C13.36 22.25 14.72 21.74 15.77 20.72C18.63 17.97 21.79 13.61 20.62 8.45ZM12 13.46C10.26 13.46 8.84997 12.05 8.84997 10.31C8.84997 8.57 10.26 7.16 12 7.16C13.74 7.16 15.15 8.57 15.15 10.31C15.15 12.05 13.74 13.46 12 13.46Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1203_111">
                        <rect width="24" height="24" fill="currentColor" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md order-1 order-md-0 text-center text-md-start mb-10 mb-md-0">
            <span class="copyright-title">Copyright © Expertware, 2023</span>
          </div>
          <div class="col-6 col-md order-1 order-md-0">
            <div class="row justify-content-end">
              <div class="col col-md-2">
                <a title="Facebook" rel="noreferrer" target="_blank" href="https://www.facebook.com/Expertware-100567287992718/">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="text-transparent-white">
                    <path
                      d="M22 15.609C22 19.613 19.613 22 15.609 22H14.3C13.695 22 13.2 21.505 13.2 20.9V14.553C13.2 14.256 13.442 14.003 13.739 14.003L15.675 13.97C15.829 13.959 15.961 13.849 15.994 13.695L16.379 11.594C16.412 11.396 16.258 11.209 16.049 11.209L13.706 11.242C13.398 11.242 13.156 11 13.145 10.703L13.101 8.008C13.101 7.832 13.244 7.67801 13.431 7.67801L16.071 7.634C16.258 7.634 16.401 7.49102 16.401 7.30402L16.357 4.66399C16.357 4.47699 16.214 4.334 16.027 4.334L13.057 4.37801C11.231 4.41101 9.77901 5.907 9.81201 7.733L9.867 10.758C9.878 11.066 9.63601 11.308 9.32801 11.319L8.008 11.341C7.821 11.341 7.67801 11.484 7.67801 11.671L7.71101 13.761C7.71101 13.948 7.854 14.091 8.041 14.091L9.36101 14.069C9.66901 14.069 9.91099 14.311 9.92199 14.608L10.021 20.878C10.032 21.494 9.53698 22 8.92098 22H6.391C2.387 22 0 19.613 0 15.598V6.391C0 2.387 2.387 0 6.391 0H15.609C19.613 0 22 2.387 22 6.391V15.609Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
              <div class="col col-md-2">
                <a title="YouTube" rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UC_SuMnmMxOuk1HaxpCnmt-Q">
                  <svg width="22" height="18" viewBox="0 0 22 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="text-transparent-white">
                    <path
                      d="M16.5 0H5.5C2.2 0 0 2.25 0 5.625V12.375C0 15.75 2.2 18 5.5 18H16.5C19.8 18 22 15.75 22 12.375V5.625C22 2.25 19.8 0 16.5 0ZM13.079 10.1587L10.362 11.8238C9.26198 12.4988 8.35997 11.9813 8.35997 10.665V7.32376C8.35997 6.00751 9.26198 5.49001 10.362 6.16501L13.079 7.82999C14.124 8.48249 14.124 9.5175 13.079 10.1587Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
              <div class="col col-md-2">
                <a title="Instagram" rel="noreferrer" target="_blank" href="https://instagram.com/expertware_net">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="text-transparent-white">
                    <path
                      d="M15.6168 0H6.39419C2.38819 0 0 2.387 0 6.391V15.598C0 19.613 2.38819 22 6.39419 22H15.6058C19.6118 22 22 19.613 22 15.609V6.391C22.011 2.387 19.6228 0 15.6168 0ZM11.0055 15.268C8.65031 15.268 6.73536 13.354 6.73536 11C6.73536 8.646 8.65031 6.732 11.0055 6.732C13.3607 6.732 15.2756 8.646 15.2756 11C15.2756 13.354 13.3607 15.268 11.0055 15.268ZM17.5207 5.368C17.4657 5.5 17.3887 5.621 17.2896 5.731C17.1796 5.83 17.0585 5.907 16.9264 5.962C16.7944 6.017 16.6513 6.05 16.5082 6.05C16.2111 6.05 15.9359 5.94 15.7268 5.731C15.6278 5.621 15.5507 5.5 15.4957 5.368C15.4407 5.236 15.4077 5.093 15.4077 4.95C15.4077 4.807 15.4407 4.664 15.4957 4.532C15.5507 4.389 15.6278 4.279 15.7268 4.169C15.98 3.916 16.3652 3.795 16.7173 3.872C16.7944 3.883 16.8604 3.905 16.9264 3.938C16.9925 3.96 17.0585 3.993 17.1245 4.037C17.1796 4.07 17.2346 4.125 17.2896 4.169C17.3887 4.279 17.4657 4.389 17.5207 4.532C17.5758 4.664 17.6088 4.807 17.6088 4.95C17.6088 5.093 17.5758 5.236 17.5207 5.368Z"
                      fill="currentColor"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPageFooter",
  props: {
    dynamicHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pages: [
        {
          Name: "Acasă",
          Link: "",
        },
        {
          Name: "Termeni și condiții",
          Link: "",
        },
        {
          Name: "Cum funcționează",
          Link: "",
        },
        {
          Name: "Politică de confidențialitate",
          Link: "",
        },
        {
          Name: "Încercare gratuită",
          Link: "",
        },
        {
          Name: "Politică de cookie-uri",
          Link: "",
        },
        {
          Name: "Contact",
          Link: "",
        },
        {
          Name: "GDPR",
          Link: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg-footer-fixed {
  padding-top: 120px;
  padding-bottom: 32px;
  background: linear-gradient(87.6deg, #188a94 0%, #006871 100%);
}

.bg-footer-dynamic-height {
  padding-top: 11%;
  padding-bottom: 32px;
  background: linear-gradient(87.6deg, #188a94 0%, #006871 100%);
}

.copyright-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #f9f9f9f0;
}

hr {
  height: 1px;
  color: white;
  background: white;
  font-size: 0;
  border: 0;
}

.contacts div a {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 12px;
}

.contacts span {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-transparent-white {
  color: #ffffff8f;
}
.contacts div svg {
  margin-right: 24px;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #f9f9f9f0;
}

.category-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 35px;
}

.expert-eval-title {
  font-size: 40px;
  font-weight: 400;
  line-height: 46px;
}

.expert-eval-description {
  margin-top: 32px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #f9f9f9f0;
}
</style>
