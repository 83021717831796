<template>
  <!-- <div class="modal fade" id="assign-evaluation-modal" tabindex="-1" aria-labelledby="assign-evaluation-modal-label" data-bs-backdrop="static"> -->
  <div class="modal fade" id="assign-evaluation-modal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <!-- Wizzard -->
        <div class="scopes-wizzard">
          <div class="row align-items-center m-0 mt-4">
            <div class="col-auto position-relative border-right p-0">
              <div class="ps-8 pe-10">
                <h1 id="assign-evaluation-modal-label">Create evaluation</h1>
                <p class="m-0 d-block">
                  Evaluation: <span class="text-dark-green fw-bold">{{ selectedEvalTemplateProp.Name }}</span>
                </p>
              </div>
              <hr class="green-line" />
            </div>

            <div class="col-auto text-center position-relative p-0">
              <h5 class="text-green mb-0">Scope</h5>
              <img v-if="step == 1" class="wizzard-step-img" src="@/assets/images/icons/small-green-circle.png" />
              <hr v-if="step == 1" class="half-left-green-line" />
              <hr v-if="step > 1" class="full-green-line" />
            </div>

            <div class="col-auto text-center position-relative p-0">
              <h5 class="mb-0" v-bind:class="[step >= 2 ? 'text-green' : 'text-muted']">Members</h5>

              <img v-if="step == 2" class="wizzard-step-img" src="@/assets/images/icons/small-green-circle.png" />
              <hr v-if="step == 2" class="half-left-green-line" />
              <hr v-if="step > 2" class="full-green-line" />
            </div>

            <div class="col-auto text-center position-relative p-0">
              <h5 class="mb-0" v-bind:class="[step >= 3 ? 'text-green' : 'text-muted']">Details</h5>
              <img v-if="step == 3" class="wizzard-step-img" src="@/assets/images/icons/small-green-circle.png" />
              <hr v-if="step == 3" class="half-left-green-line" />
              <hr v-if="step > 3" class="full-green-line" />
            </div>

            <div class="col-auto text-center position-relative p-0">
              <h5 class="text-muted mb-0">Summary</h5>
              <img v-if="step >= 4" class="wizzard-step-img" src="@/assets/images/icons/small-green-circle.png" />
              <hr v-if="step >= 4" class="half-left-green-line" />
            </div>

            <div class="col text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
          <hr class="wizzard-line" />
        </div>
        <!--End Wizzard -->
        <div class="modal-body">
          <!-- Evaluation scope -->
          <div v-if="step == 1">
            <h3>Choose the evaluation scope</h3>
            <div class="row text-center">
              <div v-for="(scope, index) in scopeCategories" v-bind:key="index" class="col mb-4">
                <div class="card shadow-sm" :class="{ 'selected-scope ': selectedScopeCategory.Id == scope.Id }" @click="SelectedScopeCategory(scope)">
                  <div class="card-body">
                    <img class="mb-5" v-bind:src="require('@/assets/images/scopes-icons/' + scope.Name + '.png')" />
                    <h3 class="mb-0">{{ scope.Name }}</h3>
                  </div>
                </div>
              </div>
            </div>

            <!-- Evaluation target -->
            <div v-if="selectedScopeCategory.Id">
              <h3>Set the evaluation target</h3>
              <div class="row align-items-center">
                <div v-bind:key="index" v-for="(scope, index) in scopes" class="col mb-3">
                  <div class="card shadow-sm shadow-hover" :class="{ 'selected-scope ': selectedScope.Id == scope.Id }" @click="SelectedScope(scope)">
                    <div class="card-body text-center">
                      <img class="mb-5" v-bind:src="require('@/assets/images/scopes-icons/' + scope.Scope + '.png')" />
                      <h5>{{ scope.Scope }}</h5>
                      <small>{{ scope.Description }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--End Evaluation target -->
          </div>

          <!--End Evaluation scope -->
          <div v-if="step == 2">
            <h3>Select participants</h3>
            <div v-if="selectedScope.Id == 6 || selectedScope.Id == 8 || selectedScope.Id == 7">
              <check-groups-table-component :itemsOfChosedScopeProps="itemsOfChosedScope" :scope="selectedScope" @getMembersEmit="GetMembers" @searchForItemsEmit="PrepareScope">
              </check-groups-table-component>
            </div>
            <div v-if="selectedScope.Id == 4 || selectedScope.Id == 5">
              <check-projects-table-component
                :selectedScopeProp="selectedScope"
                :itemsOfChosedScopeProps="itemsOfChosedScope"
                @getMembersEmit="GetMembers"
                @searchForItemsEmit="PrepareScope"
              ></check-projects-table-component>
            </div>
            <div v-if="selectedScope.Id == 2 || selectedScope.Id == 3">
              <check-teams-table-component
                :selectedScopeProp="selectedScope"
                :itemsOfChosedScopeProps="itemsOfChosedScope"
                @getMembersEmit="GetMembers"
                @searchForItemsEmit="PrepareScope"
              ></check-teams-table-component>
            </div>
          </div>

          <div v-if="step == 3">
            <div class="row align-items-start">
              <h3>Set evaluation details</h3>

              <div class="col">
                <div class="form-group">
                  <label>Evaluation name</label>
                  <input :class="{ 'border-danger': groupName.length < 3 }" class="form-control" placeholder="Evaluate " v-model="groupName" />
                </div>
                <span class="text-danger" v-if="groupName.length < 3">Name requires minimum 3 and maximum 200 characters.</span>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Max Score</label>
                  <input :class="{ 'border-danger': !maxScore || maxScore > 10 || maxScore <= 0 }" class="form-control" placeholder="Evaluation questions max score" v-model="maxScore" />
                </div>
                <span class="text-danger" v-if="!maxScore || maxScore > 10 || maxScore <= 0">Score requires minimum 1 and maximum 10.</span>
              </div>
            </div>

            <div class="row align-items-start mb-10 mt-3">
              <div class="col">
                <h3 class="m-0">Active period</h3>
                <small>The period in which the assessment is open</small>
                <div class="bg-gray-100 border p-3 rounded mt-3">
                  <div class="row mt-3">
                    <div class="col">
                      <div class="form-group">
                        <label>Start date</label>
                        <div class="input-group">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <!-- :disabledDates="disabledDates" -->
                          <Datepicker
                            :open-date="new Date()"
                            v-model="startInterval"
                            class="form-control custom-datepicker"
                            :class="{ 'border-danger': !startInterval || DateIsBeforeToday(startInterval) }"
                            placeholder="02-07.2023"
                            :enableTimePicker="false"
                            autoApply
                            name="Start date"
                            format="dd-MM-yyyy"
                            :readonly="startNow"
                          ></Datepicker>
                        </div>
                      </div>
                      <span class="text-danger" v-if="startInterval && DateIsBeforeToday(startInterval)">The start date must be greater than the date now.</span>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>End date</label>
                        <div class="input-group">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Datepicker
                            v-model="endInterval"
                            :class="{ 'border-danger': !endInterval && endInterval < startInterval }"
                            class="form-control custom-datepicker"
                            placeholder="02-07.2023"
                            :enableTimePicker="false"
                            autoApply
                            name="End date"
                            format="dd-MM-yyyy"
                          ></Datepicker>
                        </div>
                      </div>
                      <span class="text-danger" v-if="endInterval && endInterval < startInterval">The end date must be greater than the start date.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <h3 class="m-0">Evaluated period</h3>
                <small> The period for which the assessment is made</small>
                <div class="bg-gray-100 border p-3 rounded mt-3">
                  <div class="row mt-3">
                    <div class="col">
                      <div class="form-group">
                        <label>Start date</label>
                        <div class="input-group">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Datepicker
                            v-model="startDate"
                            :class="{ 'border-danger': !startDate }"
                            class="form-control custom-datepicker"
                            placeholder="02-07.2023"
                            :enableTimePicker="false"
                            autoApply
                            name="Start date"
                            format="dd-MM-yyyy"
                          ></Datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>End date</label>
                        <div class="input-group">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Datepicker
                            v-model="endDate"
                            class="form-control custom-datepicker"
                            :class="{ 'border-danger': !endDate || endDate < startDate }"
                            placeholder="02-07.2023"
                            :enableTimePicker="false"
                            autoApply
                            name="End date"
                            format="dd-MM-yyyy"
                          ></Datepicker>
                        </div>
                        <span class="text-danger" v-if="endDate && endDate < startDate">The end date must be greater than the start date.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card border shadow-hover" :class="{ 'selected-scope ': startNow }" @click="StartNow()">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <i class="far fa-3x" :class="[startNow ? 'text-dark-green fa-square-check' : 'fa-square']"></i>
                      </div>
                      <div class="col">
                        <h5 class="m-0">Start now</h5>
                        <small>Start the assessment after the assignment process</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div v-if="IsEditable() == true" class="card border shadow-hover" :class="{ 'selected-scope ': isEditable }" @click="isEditable = !isEditable">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <i class="far fa-3x" :class="[isEditable ? 'text-dark-green fa-square-check' : 'fa-square']"></i>
                      </div>
                      <div class="col">
                        <h5 class="m-0">Editable</h5>
                        <small>User can add extra categories and questions</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="card border cursor-default" style="cursor: default">
                  <div class="card-body bg-gray-200 card-body">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <i class="far fa-3x fa-square-xmark"></i>
                      </div>
                      <div class="col">
                        <h5 class="m-0">Editable</h5>
                        <small
                          >This action is disabled because are involved <span class="text-green fw-bolder">{{ GetItems().length }}</span> items with the same scope.</small
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col" v-if="selectedScope.Id != 1 && selectedScope.Id != 8">
                <div class="card border shadow-hover" :class="{ 'selected-scope ': selfAssessment }" @click="selfAssessment = !selfAssessment">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <i class="far fa-3x" :class="[selfAssessment ? 'text-dark-green fa-square-check' : 'fa-square']"></i>
                      </div>
                      <div class="col">
                        <h5 class="m-0">Self assessment</h5>
                        <small>Create self assesment for <b>{{ selectedScope.Scope }}</b></small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col" v-if="selectedScope.Id != 2 && selectedScope.Id != 4 && selectedScope.Id != 6">
                <div class="card border shadow-hover" :class="{ 'selected-scope ': isAnonim }"
                  @click="isAnonim = !isAnonim">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <i class="far fa-3x" :class="[isAnonim ? 'text-dark-green fa-square-check' : 'fa-square']"></i>
                      </div>
                      <div class="col">
                        <h5><span v-if="!isAnonim">No </span> Anonymous</h5>
                        <small v-if="isAnonim">
                          Anonymous</small>
                        <small v-else>
                          No <b> Anonymous</b>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="step == 4">
            <h3>Evaluation summary</h3>

            <div class="row">
              <div class="col-12 col-md mb-5">
                <div class="border border-1 h-100 p-4 rounded">
                  <h5>{{ selectedScope.Scope }} Evaluation</h5>
                  <small>{{ selectedScope.Description }}</small>
                </div>
              </div>
              <div class="col-12 col-md mb-5">
                <div class="border border-1 h-100 p-4 rounded">
                  <div class="row align-items-center">
                    <div class="col">
                      <h5>Max Score</h5>
                      <small>The maximum mark that a question can have</small>
                    </div>
                    <div class="col-3 text-center">
                      <h1>{{ maxScore }}</h1>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="selectedScope.Id != 1" class="col-12 mb-5">
                <div class="border border-1 h-100 p-4 rounded">
                  <!-- <h4>Participants</h4> -->
                  <h5 class="d-flex">{{ selectedScopeCategory.Name }} <span v-if="GetItems().length > 1">s</span></h5>
                  {{ GetItems().length }}
                  <span v-bind:key="item" v-for="(item, index) in GetItems()"> {{ item }} <span class="text-green" v-if="GetItems().length > 1 && GetItems().length != index + 1"> | </span></span>
                </div>
              </div>
            </div>

            <hr />

            <h3>Evaluation dates</h3>
            <div class="row">
              <div class="col-12 col-md mb-5">
                <div class="border border-1 h-100 p-4 rounded">
                  <h5>Active period</h5>
                  <small
                    >The period in which the assessment will be open is from <span class="fs-7 fw-bold text-green">{{ $utils.formatDateNiceText(startInterval) }}</span> to
                    <span class="fs-7 fw-bold text-green">{{ $utils.formatDateNiceText(endInterval) }}</span>
                  </small>
                </div>
              </div>
              <div class="col-12 col-md mb-5">
                <div class="border border-1 h-100 p-4 rounded">
                  <h5>Evaluated period</h5>
                  <small
                    >The evaluation will be done for the period <span class="fs-7 fw-bold text-green">{{ $utils.formatDateNiceText(startDate) }}</span> -
                    <span class="fs-7 fw-bold text-green">{{ $utils.formatDateNiceText(endDate) }}</span>
                  </small>
                </div>
              </div>
            </div>

            <hr />

            <h3>Evaluation options</h3>
            <div class="row">
              <div class="col-12 col-md mb-5">
                <div class="border border-1 h-100 p-4 rounded">
                  <h5><span v-if="isEditable == false">Not </span> Editable</h5>
                  <small>Leader will <span v-if="isEditable == false"> not </span> be able to add extra categories and questions above the template structure</small>
                </div>
              </div>

              <div class="col-12 col-md mb-5" v-if="selectedScope.Id != 1 && selectedScope.Id != 8">
                <div class="border border-1 h-100 p-4 rounded">
                  <h5><span v-if="!selfAssessment">No </span> Self Assesment</h5>
                  <small v-if="selfAssessment">
                    <b>{{ selectedScope.Scope }}</b> will have to do their self assessment</small
                  >
                  <small v-else>
                    No self assessment for <b> {{ selectedScope.Scope }}</b>
                  </small>
                </div>
              </div>
              <div class="col-12 col-md mb-5"
                v-if="selectedScope.Id != 2 && selectedScope.Id != 4 && selectedScope.Id != 6">
                <div class="border border-1 h-100 p-4 rounded">
                  <h5><span v-if="!isAnonim">No </span> Anonymous</h5>
                  <small v-if="isAnonim">Anonymous</small>
                  <small v-else>
                    No anonymous
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer" row>
          <div class="col">
            <button v-if="step > 1" type="button" class="btn btn-secondary" @click="Back">Back</button>
          </div>
          <div class="col-auto">
            <button v-if="step == 4" type="button" class="btn btn-gradient-success" data-bs-dismiss="modal" @click="AssignEvaluation" v-bind:disabled="StepVerification()">Assign</button>

            <button v-else class="btn btn-gradient-success" type="button" v-bind:disabled="StepVerification()" @click="Next">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <team-preview-modal-component :previewModalItemProp="previewModalItem"></team-preview-modal-component>
</template>

<script>
import TeamPreviewModalComponent from "@/components/Modals/Team/Preview/TeamPreviewModalComponent.vue";
import CheckGroupsTableComponent from "@/components/Tables/CheckGroupsTableComponent.vue";
import CheckProjectsTableComponent from "@/components/Tables/CheckProjectsTableComponent.vue";
import CheckTeamsTableComponent from "@/components/Tables/CheckTeamsTableComponent.vue";
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import utils from "@/utils/utils.js";
import { Field, Form } from "vee-validate";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
defineRule("required", required);

export default {
  Name: "eval-preview-modal-Component",
  components: {
    TeamPreviewModalComponent,
    CheckGroupsTableComponent,
    CheckProjectsTableComponent,
    CheckTeamsTableComponent,
    Field,
    Form,
  },
  props: {
    selectedEvalTemplateProp: { type: Object, default: {} },
  },
  emits: ["assignEvaluationEmit"],

  data() {
    return {
      scopeCategories: [],
      scopes: [],
      selectedScopeCategory: {},
      selectedScope: {},
      itemsOfChosedScope: [],
      step: 1,
      endDate: null,
      startDate: null,
      previewModalItem: [],
      maxScore: 5,
      isEditable: false,
      selfAssessment: false,
      startNow: null,
      groupName: null,
      endInterval: null,
      startInterval: null,
      dateNow: null,
      isAnonim: false
      // disabledDates: {
      //   to: new Date(Date.now() - 8640000),
      // },
    };
  },

  methods: {
    DateIsBeforeToday: function (date) {
      if (moment().diff(date, "days") <= 0) {
        return false;
      }
      return true;
    },
    StartNow: function () {
      this.startNow = !this.startNow;
      if (this.startNow === true) {
        this.startInterval = new Date();
        this.isEditable = false;
      }
    },
    IsEditable: function () {
      if (this.itemsOfChosedScope.filter((x) => x.Checked == true).length > 1) return false;
      if (this.startNow == true) return false;
      return true;
    },
    GetItems: function () {
      return this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.Name);
    },
    GetMembers: async function (item) {
      this.previewModalItem = [];
      switch (this.selectedScope.Id) {
        case 2:
        case 3:
          await this.GetTeamMembers(item.Id);
          $("#team-preview-modal").modal("show");
          break;
        case 4:
        case 5:
          await this.GetProjectMembers(item.ProjectId);
          $("#team-preview-modal").modal("show");
          break;
        case 6:
        case 8:
        case 7:
          await this.GetGroupMembersByGroup(item.Id);
          $("#team-preview-modal").modal("show");
          break;
        default:
          break;
      }
    },
    GetTeamMembers: async function (teamId) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Team/getTeamMembers?teamId=" + teamId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.previewModalItem = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetProjectMembers: async function (projectId) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Project/getProjectMembersByProject?projectId=" + projectId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.previewModalItem = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetGroupMembersByGroup: async function (groupId) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationGroup/getGroupMembersByGroup?groupId=" + groupId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.previewModalItem = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    StepVerification: function () {
      switch (this.step) {
        case 1:
          if (this.selectedScope.Id && this.selectedScopeCategory.Id) {
            return false;
          }
          return true;
        case 2:
          if (this.itemsOfChosedScope.filter((i) => i.Checked === true).length > 0) {
            return false;
          }
          return true;
        case 3:
        case 4:
          if (
            this.startDate &&
            this.endDate &&
            this.startDate < this.endDate &&
            this.maxScore > 0 &&
            this.maxScore <= 10 &&
            this.startInterval &&
            this.endInterval &&
            moment().diff(this.startInterval, "days") <= 0 &&
            this.startInterval < this.endInterval &&
            this.groupName.length >= 3
          ) {
            return false;
          }
          return true;
      }
    },
    ClearModalAssign: function () {
      this.step = 1;
      this.selectedScopeCategory = {};
      this.selectedScope = {};
      this.scopes = [];
      this.itemsOfChosedScope = [];
      this.startDate = new Date(new Date().getFullYear(), 0, 1);
      this.endDate = new Date(new Date().getFullYear(), 11, 31);
      this.maxScore = 5;
      this.isEditable = false;
      this.selfAssessment = false;
      this.startNow = false;
      this.startInterval = new Date();
      this.endInterval = new Date(new Date().getFullYear(), 11, 31);
      this.groupName = null;
    },
    Back: function () {
      if (this.step == 3) {
        this.itemsOfChosedScope = [];
      }
      if (this.step > 1) {
        if (this.selectedScopeCategory.Id == 10 && this.step == 3) {
          this.step = 1;
        } else {
          this.step--;
        }
      }
    },

    Next: function () {
      if (this.selectedScope.Id == 1 && this.step == 1) {
        this.step = 3;
      } else {
        this.step++;
        if (this.step == 2) {
          this.PrepareScope();
        }
      }

      if (this.step < 4) {
        this.ConstructGroupName();
      }
    },

    ConstructGroupName: function () {
      if (this.GetItems().length == 1) {
        switch (this.selectedScope?.Scope) {
          case "Team Members":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.Name)[0];
            break;
          case "Team Leader":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.LeaderName)[0];
            break;
          case "Project Members":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.Name)[0];
            break;
          case "Project Manager":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.ManagerName)[0];
            break;
          case "Group Member":
          case "Group Mesh":
          case "Group Leaders":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.Name)[0];
            break;
          default:
            this.groupName = utils.formatDateNiceText(this.startDate) + " - " + utils.formatDateNiceText(this.endDate);
        }
      }

      if (this.GetItems().length > 1) {
        switch (this.selectedScope?.Scope) {
          case "Team Members":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).length + " Teams";
            break;
          case "Team Leader":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).length + " Leaders";
            break;
          case "Project Members":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).length + " Projects";
            break;

          case "Project Manager":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).length + " Managers";
            break;
          case "Group Member":
          case "Group Mesh":
          case "Group Leaders":
            this.groupName = this.itemsOfChosedScope.filter((i) => i.Checked === true).length + " Groups";
            break;
          default:
            this.groupName = utils.formatDateNiceText(this.startDate) + " - " + utils.formatDateNiceText(this.endDate);
        }
      }
      this.groupName = this.groupName + " | " + utils.formatDate(this.startDate) + " - " + utils.formatDate(this.endDate);
    },

    PrepareAssigneTo: function () {
      switch (this.selectedScope?.Scope) {
        case "Team Members":
        case "Team Leader":
          return this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.Id);
        // case "Team Leader":
        //   return this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.LeaderId);
        case "Project Members":
        case "Project Manager":
          return this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.ProjectId);
        // case "Project Manager":
        //   return this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.ManagerId);
        case "Group Member":
        case "Group Mesh":
          return this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.Id);
        case "Group Leaders":
          return this.itemsOfChosedScope.filter((i) => i.Checked === true).map((i) => i.Id);

        default:
          return (this.itemsOfChosedScope = []);
      }
    },

    AssignEvaluation: async function () {
      const assignEval = {
        EvalTempId: this.selectedEvalTemplateProp.Id,
        ScopeId: this.selectedScope.Id,
        AssignateTo: this.PrepareAssigneTo(),
        StartDate: utils.removeTimeFromDate(this.startDate),
        EndDate: utils.removeTimeFromDate(this.endDate),
        MaxGrade: this.maxScore,
        IsEditable: this.isEditable,
        SelfAssessment: this.selfAssessment,
        StartNow: this.startNow,
        GroupName: this.groupName,
        StartInterval: utils.removeTimeFromDate(this.startInterval),
        EndInterval: utils.removeTimeFromDate(this.endInterval),
        IsAnonim: this.isAnonim
      };
      this.$emit("assignEvaluationEmit", assignEval);
    },

    GetTeams: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);
      if (!searchText) {
        searchText = "";
      }

      await this.$axios
        .get("api/Team/getTeamsDetailsByRole?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.itemsOfChosedScope = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    // GetTeamLeaders: async function (searchText) {
    //   store.dispatch("changeLoaderStatus", true);
    //   if (!searchText) {
    //     searchText = "";
    //   }

    //   await this.$axios
    //     .get("api/Team/getTeamLeaders?searchText=" + searchText, {
    //       headers: {
    //         Authorization: "Bearer " + auth.getJwtToken().Value,
    //       },
    //     })
    //     .then((response) => {
    //       this.itemsOfChosedScope = response.data;
    //       store.dispatch("changeLoaderStatus", false);
    //     })
    //     .catch((error) => {
    //       store.dispatch("toastNotify", {
    //         Type: "error",
    //         Title: error.response.data.message,
    //       });
    //       store.dispatch("changeLoaderStatus", false);
    //     });
    // },
    GetProjects: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);

      if (!searchText) {
        searchText = "";
      }

      await this.$axios
        .get("api/Project/getProjectsDetailsByRole?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.itemsOfChosedScope = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    // GetProjectManagers: async function (searchText) {
    //   store.dispatch("changeLoaderStatus", true);

    //   if (!searchText) {
    //     searchText = "";
    //   }
    //   await this.$axios
    //     .get("api/Project/getAllProjectManagers?searchText=" + searchText, {
    //       headers: {
    //         Authorization: "Bearer " + auth.getJwtToken().Value,
    //       },
    //     })
    //     .then((response) => {
    //       this.itemsOfChosedScope = response.data;
    //       store.dispatch("changeLoaderStatus", false);
    //     })
    //     .catch((error) => {
    //       store.dispatch("toastNotify", {
    //         Type: "error",
    //         Title: error.response.data.message,
    //       });
    //       store.dispatch("changeLoaderStatus", false);
    //     });
    // },
    GetGroups: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);

      if (!searchText) {
        searchText = "";
      }
      await this.$axios
        .get("api/EvaluationGroup/getEvaluationGroupByRole?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.itemsOfChosedScope = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetGroupsLeaders: async function (searchText) {
      store.dispatch("changeLoaderStatus", true);

      if (!searchText) {
        searchText = "";
      }
      await this.$axios
        .get("api/EvaluationGroup/getAllGroupsLeaders?searchText=" + searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.itemsOfChosedScope = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    SelectedScope: function (scope) {
      this.selectedScope = scope;

      if (scope.Id == 1) {
        // this.step = 4;
        this.groupName = "Global evaluation";
      }
    },

    PrepareScope: function (searchText) {
      switch (this.selectedScope.Scope) {
        case "Team Members":
        case "Team Leader":
          this.GetTeams(searchText);
          break;
        // case "Team Leader":
        //   this.GetTeamLeaders(searchText);
        //   break;
        case "Project Members":
        case "Project Manager":
          this.GetProjects(searchText);
          break;
        // case "Project Manager":
        //   this.GetProjectManagers(searchText);
        //   break;
        case "Group Member":
        case "Group Mesh":
        case "Group Leaders":
          this.GetGroups(searchText);
          break;

        default:
          break;
      }
    },
    SelectedScopeCategory: function (scope) {
      this.selectedScopeCategory = scope;
      this.selectedScope = {};
      this.GetScopesByScopeCategory();
    },
    GetScopesCategories: async function () {
      // store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/EvaluationPlan/getScopeCategoriesByRole?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.scopeCategories = response.data;
          // store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          // store.dispatch("changeLoaderStatus", false);
        });
    },

    GetScopesByScopeCategory: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/EvaluationTemplate/getScopesByScopeCategory?scopeCategoryId=" + this.selectedScopeCategory.Id, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.scopes = response.data;

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  created: function () {
    this.dateNow = new Date();
    this.GetScopesCategories();
  },
};
</script>

<style scoped>
.wizzard-step-img {
  position: absolute;
  z-index: 5;
  width: 15px;
  top: 41px;
  left: 68px;
}
.wizzard-line {
  position: absolute;
  top: 66px;
  z-index: 0;
  width: 100%;
  height: 2px;
}

.green-line,
.half-left-green-line,
.full-green-line {
  width: 101%;
  position: absolute;
  background-color: #4dc4b3;
  top: 53px;
  z-index: 1;
  height: 2px;
  opacity: 1;
}

.half-left-green-line {
  width: 50%;
  top: 35px;
}

.full-green-line {
  width: 100%;
  top: 35px;
}

h3 {
  color: #495057;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-body {
  max-height: 80vh;
  overflow: auto;
}

.fa-circle-dot {
  font-size: 15px;
}

.scopes-wizzard {
  position: relative;

  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
}

.scopes-wizzard .col-auto {
  min-width: 150px;
}

.card {
  cursor: pointer;
  border: 1px solid white;
  height: 100%;
}

.card:hover {
  border: 1px solid #4dc4b3;
  background: #f6fcfb;
}

.selected-scope {
  border: 1px solid #4dc4b3 !important;
  background: #f6fcfb;
}

.scope-title {
  font-size: 17px;
  color: #6c757d;
  font-weight: 500;
}

.scope-title i {
  font-size: 17px;
  color: #6c757d;
  margin-right: 5px;
}

/* .modal-header,
.modal-footer {
  display: block;
} */
</style>
