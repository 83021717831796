<template>
  <div class="modal fade" id="edit-group-modal" tabindex="-1" aria-labelledby="edit-group-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="Save" ref="editGroupForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="edit-group-modal-label">Edit group</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>

          <div class="modal-body">
            <h3>Evaluation details</h3>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="label">Name</label>
                  <Field type=" text" name="groupName" v-slot="{ field }" rules="required|min: 3|max: 200" placeholder="Group name" v-model="groupDetails.Name">
                    <input v-bind="field" class="form-control" id="group-name-textarea" rows="2" placeholder="Group name" />
                  </Field>
                  <div v-if="errors.groupName" class="fv-plugins-message-container invalid-feedback">Title requires minimum 3 and maximum 200 characters.</div>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="form-label">Score</label>
                  <Field type="number" name="maxScore" class="form-control form-control-solid ps-2" placeholder="Enter the max score..." v-model="groupDetails.MaxScore" rules="required|between: 1,10" />
                  <div v-if="errors.maxScore" class="fv-plugins-message-container invalid-feedback">Max score must be between 1 and 10</div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col">
                <h3 class="m-0">Active period</h3>
                <small> The period for which the assessment is made</small>
                <div class="bg-gray-100 border p-3 rounded mt-3">
                  <div class="row">
                    <div class="col">

                      <div class="form-group">
                        <label class="label">Start date</label>
                        <div class="input-group mb-5">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Field v-model="groupDetails.StartDate" name="startDate" v-slot="{ field }" rules="required">
                            <Datepicker v-bind="field" v-model="groupDetails.StartDate" class="form-control form-control-solid ps-2 custom-datepicker" placeholder="Select start date" :enableTimePicker="false" autoApply format="dd-MM-yyyy"></Datepicker>
                          </Field>
                        </div>
                        <div v-if="errors.startDate" class="fv-plugins-message-container invalid-feedback">Start date is required.</div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="form-group">
                        <label class="label">End date </label>
                        <div class="input-group mb-5">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Field name="endDate" v-slot="{ field }" :rules="{ end_date_rule: [groupDetails.StartDate, groupDetails.EndDate] }">
                            <Datepicker v-bind="field" v-model="groupDetails.EndDate" class="form-control custom-datepicker" placeholder="Select end date" :enableTimePicker="false" autoApply format="dd-MM-yyyy"></Datepicker>
                          </Field>
                        </div>
                        <div v-if="errors.endDate" class="fv-plugins-message-container invalid-feedback">{{ errors.endDate }}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col">

                <h3 class="m-0">Evaluated period</h3>
                <small> The period for which the assessment is made</small>
                <div class="bg-gray-100 border p-3 rounded mt-3">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label class="label">Start date</label>
                        <div class="input-group mb-5">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>

                          <Field name="startInterval" v-slot="{ field }" :rules="{ start_date_rule: [groupDetails.StartInterval] }">
                            <Datepicker v-bind="field" v-model="groupDetails.StartInterval" class="form-control form-control-solid ps-2 custom-datepicker" placeholder="Select start date" :enableTimePicker="false" autoApply format="dd-MM-yyyy"></Datepicker>
                          </Field>
                        </div>
                        <div v-if="errors.startInterval" class="fv-plugins-message-container invalid-feedback">{{ errors.startInterval }}</div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="form-group">
                        <label class="label">End date</label>
                        <div class="input-group mb-5">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Field name="endInterval" v-slot="{ field }" :rules="{ end_date_rule: [groupDetails.StartInterval, groupDetails.EndInterval] }">
                            <Datepicker v-bind="field" v-model="groupDetails.EndInterval" class="form-control custom-datepicker" placeholder="Select end date" :enableTimePicker="false" autoApply format="dd-MM-yyyy"></Datepicker>
                          </Field>
                        </div>
                        <div v-if="errors.endInterval" class="fv-plugins-message-container invalid-feedback">{{ errors.endInterval }}</div>
                      </div>
                    </div>

                    <div v-if="selectedScope && selectedScope.Id != 1 && selectedScope.Id != 8" class="col">
                      <label class="form-label">Self assessment</label>
                      <button type="button" class="btn btn-white border w-100" v-on:click="groupDetails.SelfAssessment = !groupDetails.SelfAssessment">
                        <i class="far fa-2x w-auto" :class="[groupDetails.SelfAssessment ? 'fa-square-check' : 'fa-square']" style="font-size: 20px"></i>
                        For {{ selectedScope.Scope }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="modal-footer flex-stack">
            <button type="button" data-bs-dismiss="modal" class="border btn btn-white">Close</button>
            <button type="submit" :disabled="Object.keys(errors).length != 0" class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";

export default {
  name: "edit-group-modal-component",
  components: { Field, Form },
  props: ["groupDetails"],

  data() {
    return {
      itemsOfChosedScope: [],
      selectedScope: null,
      scopeCategories: [],
      scopes: [],
    };
  },
  emits: ["update"],

  computed: {},
  mounted() {},
  methods: {
    Save: function () {
      this.groupDetails.StartDate = moment(this.groupDetails.StartDate).format(
        "YYYY-MM-DD"
      );
      this.groupDetails.EndDate = moment(this.groupDetails.EndDate).format(
        "YYYY-MM-DD"
      );
      this.groupDetails.StartInterval = moment(
        this.groupDetails.StartInterval
      ).format("YYYY-MM-DD");
      this.groupDetails.EndInterval = moment(
        this.groupDetails.EndInterval
      ).format("YYYY-MM-DD");
      this.$emit("update", this.groupDetails);
    },
  },
  created: function () {},
};
</script>

<style scoped>
.fa-square:hover:before {
  content: "\f14a";
  color: #ddd !important;
  transition: 0.1s;
}

.card-body img {
  width: 30px;
}

.card {
  cursor: pointer;
  border: 1px solid white;
}

.scroll-table {
  max-height: 490px;
  overflow: auto;
}

.card:hover {
  border: 1px solid #4dc4b3;
  background: #f6fcfb;
}

.fa-square,
.fa-square-check {
  cursor: pointer;
}

.selected-scope {
  border: 1px solid #4dc4b3;
  background: #f6fcfb;
}

.card-body {
  padding: 2rem 5px;
}
</style>
