<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>

        <li class="breadcrumb-item">My evaluations</li>

        <li class="breadcrumb-item">
          <a href="/My-evaluations/History"> History</a>
        </li>

        <li class="breadcrumb-item">{{ evaluationDetails.Name }}</li>
        <li class="breadcrumb-item">
          <a href="#" @click="$router.go(-1)"> Members</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Answers</li>
      </ol>
    </nav>

    <div class="row align-items-end">
      <div class="col">
        <h1 class="eval-page-title">Answers</h1>
      </div>

      <div v-if="evaluationParticipants.length > 1" class="col-auto" :style="{ width: scoreColumnWidth + 32 + 'px' }">
        <div class="bg-white rounded p-4">
          <label class="mb-4 fw-bold label">Change member</label>
          <VueMultiselect v-model="selectedEmployee" track-by="EmployeeId" @select="SelectMember" :allowEmpty="false" :options="evaluationParticipants" :placeholder="'Search for member'" label="Name" :searchable="true" :internalSearch="true"></VueMultiselect>
        </div>
      </div>
    </div>

    <div class="card mb-8 mt-8 evaluation-summary-card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <h1>{{ evaluationDetails.Name }}</h1>
            <p>{{ evaluationDetails.Description }}</p>
          </div>
        </div>
        <hr />
        <div class="row align-items-center">
          <div class="col d-flex align-items-center">
            <img v-if="evaluationDetails.Scope" :src="require('@/assets/images/scopes-icons/' + evaluationDetails.Scope + '.png')" />
            <div>
              <small>Scope</small>
              <h5>{{ evaluationDetails.Scope }}</h5>
            </div>
          </div>

          <div class="col">
            <small>Team</small>
            <h5>{{ evaluationDetails.EvaluationFor }}</h5>
          </div>
          <div class="col d-flex align-items-center">
            <div class="symbol symbol-40px me-2 symbol-circle">
              <img :src="GetImgUrl(selectedEmployee.Image)" />
            </div>

            <div>
              <small>Taget</small>
              <h5>{{ selectedEmployee.Name }}</h5>
            </div>
          </div>
          <div class="col">
            <small>Period</small>
            <h5>
              {{ $moment(evaluationDetails.StartDate).format("DD.MM.YYYY") }}
              -
              {{ $moment(evaluationDetails.EndDate).format("DD.MM.YYYY") }}
            </h5>
          </div>
          <div class="col-1"></div>
          <div class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
            <div class="big-score-bage bg-dark-green">
              <h1>{{ evaluationContent.EvaluationScore }}</h1>
              <span>Your score</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="evaluationContent.Categories">
      <div class="card-body">
        <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in evaluationContent.Categories">
          <div class="row">
            <div class="col">
              <h1>{{ category.CategoryName }}</h1>
              <p>
                {{ category.CategoryDescription }}
              </p>
            </div>

            <div class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 text-gray-700">Category score</h5>
                </div>
                <div class="col-auto">
                  <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-dark-green me-2"></i>
                    {{ category.CategoryScore }}
                    <!-- 4.2 -->
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center" :id="'question-' + question.AnswerId" v-bind:key="questionIndex" v-for="(question, questionIndex) in category.Questions">
            <div class="col">
              <question-timeline-details-component :question="question"></question-timeline-details-component>
            </div>
            <div class="col-auto questions-score-div">
              <preview-score-component :question="question"></preview-score-component>
            </div>
          </div>

          <hr class="mt-15 mb-15" />
        </div>
      </div>
    </div>

    <not-found-component v-else :text="'You did not answered the questions from this assessment!'"></not-found-component>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";

import PreviewScoreComponent from "@/components/Common/Score/PreviewScoreComponent.vue";
import QuestionTimelineDetailsComponent from "@/components/Questions/QuestionTimelineDetailsComponent.vue";
import NotFoundComponent from "@/components/Common/NotFoundComponent.vue";

export default {
  name: "ManagerEvaluationAnswersDetailsView",
  components: {
    PreviewScoreComponent,
    QuestionTimelineDetailsComponent,
    NotFoundComponent,
  },

  data() {
    return {
      scoreColumnWidth: 320,
      evaluationContent: {},
      evaluationDetails: {},
      selectedEmployee: {},
      employeeId: 0,
      evaluationParticipants: [],
    };
  },

  methods: {
    SelectMember: function (member) {
      this.$router
        .push({
          name: "ManagerEvaluationAnswersDetailsView",
          params: {
            id: this.evaluationDetails.EvaluationPlanId,
            memberId: member.EmployeeId,
          },
        })
        .then(() => {
          this.$router.go();
        });
    },
    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    SelectScore: function (question) {
      const index = this.newAnswers.findIndex(
        (item) => item.Id === question.AnswerId
      );
      if (index === -1) {
        this.newAnswers.push({
          Id: question.AnswerId,
          Grade: question.Grade,
        });
      } else {
        this.newAnswers[index].Grade = question.Grade;
      }

      this.CategoryAvgScore();
    },

    CategoryAvgScore: function () {
      this.evaluationContent.EvaluationScore = 0;

      this.evaluationContent.Categories.forEach((category) => {
        category.CategoryScore = 0;
        category.Questions.forEach((question) => {
          category.CategoryScore = category.CategoryScore =
            category.CategoryScore + question.Grade;
        });

        category.CategoryScore = (
          category.CategoryScore / category.Questions.length
        ).toFixed(2);

        this.evaluationContent.EvaluationScore += Number(
          category.CategoryScore
        );
      });

      this.evaluationContent.EvaluationScore = (
        this.evaluationContent.EvaluationScore /
        this.evaluationContent.Categories.length
      ).toFixed(2);
    },

    GetEvaluationSummary: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/EvaluationPlan/getEvaluationPlanSummary?evaluationId=" +
            this.evaluationDetails.EvaluationPlanId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationContent: function () {
      store.dispatch("changeLoaderStatus", true);

      var query = new URLSearchParams({
        employeeId: this.employeeId,
        evaluationPlanId: this.evaluationDetails.EvaluationPlanId,
      });

      this.$axios
        .get(
          "api/Answers/getLoggedUserAnswersForManageOrTeamService?" + query,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationContent = response.data;

          if (this.evaluationContent.Categories) {
            this.CategoryAvgScore();
          }

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    InsertQuestions: async function () {
      await this.$axios
        .post(
          "api/Answers/insert",
          {
            EvaluationId: this.evaluationDetails.EvaluationPlanId,
            EmployeeId: this.selectedEmployee.EmployeeId,
          },
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then(() => {
          this.GetEvaluationContent();
        })
        .catch((error) => {
          this.$swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: "error",
            title: error,
          });
        });
    },

    ResetEvaluation: async function () {
      this.evaluationContent.Categories.forEach((category) => {
        category.Questions.forEach((question) => {
          this.newAnswers.push({
            Id: question.AnswerId,
            Grade: 0,
          });
        });
      });

      var self = this;
      this.$swal
        .fire({
          title: "Reset answers",
          text: "Are you sure you want to reset your answers?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        })
        .then(async function (result) {
          if (result.value) {
            await self.UpdateEvaluation();
            self.GetEvaluationContent();
          }
        });
    },

    UpdateEvaluation: async function () {
      if (this.newAnswers.length !== 0) {
        store.dispatch("changeLoaderStatus", true);
        await this.$axios
          .put("api/Answers/updateEvalResult", this.newAnswers, {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          })
          .then((response) => {
            this.$swal.fire({
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: "success",
              title: response.data,
            });
            this.newAnswers = [];
            store.dispatch("changeLoaderStatus", false);
          })
          .catch((error) => {
            this.$swal.fire({
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: "error",
              title: error.response.data.message,
            });
            store.dispatch("changeLoaderStatus", false);
          });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          icon: "warning",
          title: "No answer modified!",
        });
      }
    },

    GetEvaluationParticipants: function () {
      store.dispatch("changeLoaderStatus", true);
      this.$axios
        .get(
          "api/Employee/getEvaluationParticipants?evaluationPlanId=" +
            this.evaluationDetails.EvaluationPlanId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationParticipants = response.data;
          var self = this;
          this.evaluationParticipants.forEach(function (member) {
            if (member.EmployeeId == self.employeeId) {
              self.selectedEmployee = member;
            }
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  created() {
    this.evaluationDetails.EvaluationPlanId = this.$route.params.id;
    this.employeeId = this.$route.params.memberId;

    this.GetEvaluationSummary();
    this.GetEvaluationContent();
    this.GetEvaluationParticipants();
  },
};
</script>
