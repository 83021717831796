<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item" aria-current="page"><a href="#" class="active" @click="$router.go(-1)">My profile</a></li>
      </ol>
    </nav>

    <div class="main card">
      <div class="card-body main">
        <div class="row align-items-center">
          <div class="col-12 col-xxl-auto mb-4 mb-xxl-0">
            <div class="row me-5 me-xxl-0">
              <div class="col-auto">
                <img alt="User image" :src="GetImgUrl($store.getUser?.Image)" class="user-img" />
              </div>
              <div class="col align-items-center">
                <div class="user-name">Hi, {{ ShowFirstWordFromUserName($store.getUser?.EmployeeName) }}!</div>
                <span class="user-email">{{ $store.getUser?.UserName }}</span>
              </div>
              <div class="col-auto d-xxl-none">
                <div class="current-score">
                  <span class="value">4.1</span>
                  <span class="text"
                    >current <br />
                    score</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col ms-10 ms-xxl-0">
            <div class="d-flex align-items-center">
              <img src="../../assets/images/scopes-icons/compare-view-icons/Teams.png" />
              <div class="part-of">
                <span class="label">Team</span>
                <span class="value">Development</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-center">
              <img src="../../assets/images/scopes-icons/compare-view-icons/Projects.png" />
              <div class="part-of">
                <span class="label">Projects</span>
                <span class="value">Azure Support</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-center">
              <img src="../../assets/images/scopes-icons/compare-view-icons/Groups.png" />
              <div class="part-of">
                <span class="label">Groups</span>
                <span class="value">New Group</span>
              </div>
            </div>
          </div>
          <div class="col d-none d-xxl-block">
            <div class="current-score">
              <span class="value">4.1</span>
              <span class="text"
                >current <br />
                score</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card-body edit" @click="GetUserDetailsForEdit()">
        <span class="d-flex align-items-center"> <img src="../../assets/images/icons/pencil-line-20x20.svg" class="me-3" /> Edit </span>
      </div>
    </div>
  </div>

  <div class="row position-relative" style="margin-top: 100px">
    <div class="col line"></div>
    <div class="col-10 green line position-relative pe-0 ps-0">
      <div class="row justify-content-between">
        <div class="col-auto" v-for="(period, index) in periods" :key="index">
          <div class="period-content">
            <div class="value">{{ period.Value }}</div>
            <div class="dot"></div>
            <div class="date">
              <div style="color: #495057" class="fw-bold">{{ period.Month }}</div>
              <div>{{ period.Year }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col line"></div>
  </div>
  <div class="container" style="margin-top: 100px">
    <div class="row page-tabs">
      <div class="col-4 col-xxl-2 pe-0">
        <h3 v-bind:class="[selectedTab === 'Performance' ? 'active' : 'fw-normal']" class="pb-5 pointer" @click="ChangeTab('Performance')" style="height: 40.25px">Performance</h3>
      </div>
      <div class="col-4 col-xxl-2 p-0">
        <h3 v-bind:class="[selectedTab === 'ActiveEvaluations' ? 'active' : 'fw-normal']" class="pb-5 pointer d-flex align-items-center justify-content-center" @click="ChangeTab('ActiveEvaluations')">
          Active evaluations <span class="evaluations-tab-number ms-3">{{ evaluationsCount.Active }}</span>
        </h3>
      </div>
      <div class="col-4 col-xxl-2 p-0">
        <h3
          v-bind:class="[selectedTab === 'HistoryEvaluations' ? 'active' : 'fw-normal']"
          class="pb-5 pointer d-flex align-items-center justify-content-center"
          @click="ChangeTab('HistoryEvaluations')"
        >
          History evaluations <span class="evaluations-tab-number ms-3">{{ evaluationsCount.History }}</span>
        </h3>
      </div>
      <div class="col ps-0 d-none d-xxl-block">
        <h3 style="height: 40.25px"></h3>
      </div>
    </div>
    <div v-if="selectedTab === 'Performance'">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-xxl tab-title">Performance</div>
                <div class="col-auto">
                  <div class="input-group" style="max-width: 140px">
                    <span class="input-group-text border-0">
                      <i class="fa-solid fa-calendar-range fs-18px"></i>
                    </span>
                    <Datepicker
                      class="form-control form-control-solid ps-2 custom-datepicker"
                      placeholder="Start date"
                      :enableTimePicker="false"
                      autoApply
                      name="Start date"
                      format="dd-MM-yyyy"
                    ></Datepicker>
                  </div>
                </div>
                <div class="col-auto p-0 text-gray-400">-</div>
                <div class="col-auto">
                  <div class="input-group" style="max-width: 140px">
                    <span class="input-group-text border-0">
                      <i class="fa-solid fa-calendar-range fs-18px"></i>
                    </span>
                    <Datepicker
                      class="form-control form-control-solid ps-2 custom-datepicker"
                      placeholder="End date"
                      :enableTimePicker="false"
                      autoApply
                      name="End date"
                      format="dd-MM-yyyy"
                    ></Datepicker>
                  </div>
                </div>
                <div class="col col-xxl-3">
                  <VueMultiselect
                    select-label=""
                    @select="ChooseCategories($event, true)"
                    :options="categories"
                    placeholder="Select categories"
                    label="Name"
                    track-by="Id"
                    :multiple="true"
                  ></VueMultiselect>
                </div>
              </div>
              <hr style="background-color: #adb5bd" class="mt-5" />
              <div class="line-chart-canvas">
                <!--chart-->
                <Line :data="chartDataSimplified" :options="chartOptions" style="width: 100%" width="100%" />
                <!--end chart-->
              </div>
              <!-- <div class="row categories-legend mt-12">
                <div class="col-auto mb-3" v-for="(category, index) in selectedCategories" :key="index">
                  <div class="d-flex align-items-center" style="padding-right: 84px">
                    <button type="button" class="btn btn-remove me-2" @click="ChooseCategories(category, false)">
                      <i class="fa-regular fa-xmark"></i>
                    </button>
                    <span class="name">{{ category.Name }}</span>
                    <span class="border-legend ms-2" :style="{ backgroundColor: category.borderColor }"></span>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-xl-4 mt-5 mt-xl-0">
          <div class="card h-100">
            <div class="card-body">
              <div class="row align-items-center mb-5 pb-3">
                <div class="col top-categories-title">Top categories</div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-secondary-alternative min-w-100 pt-2 pb-2 ps-3 pe-3"
                    @click="categoriesOrdered.length && (categoriesOrderByScoreAsc = !categoriesOrderByScoreAsc)"
                  >
                    <img src="../../assets/images/icons/arrow-top-16x16.svg" :class="{ 'order-asc': categoriesOrderByScoreAsc }" />
                  </button>
                </div>
              </div>
              <div v-for="(category, index) in categoriesOrdered" :key="index" :class="{ 'mt-5 pt-5': index > 0 }" class="d-flex">
                <button type="button" class="btn btn-remove me-4" @click="ChooseCategories(category, false)">
                  <i class="fa-regular fa-xmark"></i>
                </button>
                <div class="row top-categories w-100">
                  <div class="col title">
                    {{ category.Name }}
                  </div>
                  <div class="col-auto d-flex align-items-center">
                    <span class="score me-1">
                      {{ category.AverageScore }}
                    </span>
                    <img src="../../assets/images/icons/star-green-16x16.svg" />
                  </div>
                  <div class="col-12">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :style="{ width: CalculatePercentageFromValue(category.AverageScore) + '%', backgroundColor: category.borderColor }"
                        :aria-valuenow="category.AverageScore"
                        aria-valuemin="0"
                        aria-valuemax="5"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center p-20" v-if="categoriesOrdered.length == 0">
                <img src="@/assets/images/not-found.png" class="w-100" style="max-width: 350px" />
                <span class="d-block fs-18px mt-11">There are no selected categories</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="selectedTab === 'ActiveEvaluations'">
      <UserActiveEvaluationsTabComponent @set-active-evaluations-count="SetActiveEvaluationsCount($event, 'Active')" />
    </div>
    <div v-show="selectedTab === 'HistoryEvaluations'">
      <UserHistoryEvaluationsTabComponent @set-active-evaluations-count="SetActiveEvaluationsCount($event, 'History')" />
    </div>
  </div>

  <UserProfileEditDetailsModal :employeeDetails="userDetailsForEdit" @editEmployeeEmit="EditEmployee" ref="editEmployeeRef" />
</template>
<script>
import UserProfileEditDetailsModal from "@/components/Modals/Employee/UserProfileEditDetailsModal.vue";
import UserActiveEvaluationsTabComponent from "@/components/Tabs/UserActiveEvaluationsTabComponent.vue";
import UserHistoryEvaluationsTabComponent from "@/components/Tabs/UserHistoryEvaluationsTabComponent.vue";

import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default {
  name: "UserProfileView",
  components: { Line, UserProfileEditDetailsModal, UserActiveEvaluationsTabComponent, UserHistoryEvaluationsTabComponent },
  data() {
    return {
      userDetailsForEdit: {},
      evaluationsCount: {
        Active: 0,
        History: 0,
      },

      chartColors: [
        {
          backgroundColor: "rgba(240, 15, 15, 0.2)",
          borderColor: "rgba(240, 15, 15, 1)",
          pointBackgroundColor: "rgba(240, 15, 15, 1)",
          pointHoverBorderColor: "rgba(240, 15, 15, 1)",
        },
        {
          backgroundColor: "rgba(240, 109, 15, 0.2)",
          borderColor: "rgba(240, 109, 15, 1)",
          pointBackgroundColor: "rgba(240, 109, 15, 1)",
          pointHoverBorderColor: "rgba(240, 109, 15, 1)",
        },
        {
          backgroundColor: "rgba(192, 192, 12, 0.2)",
          borderColor: "rgba(192, 192, 12, 1)",
          pointBackgroundColor: "rgba(192, 192, 12, 1)",
          pointHoverBorderColor: "rgba(192, 192, 12, 1)",
        },
        {
          backgroundColor: "rgba(147, 192, 12, 0.2)",
          borderColor: "rgba(147, 192, 12, 1)",
          pointBackgroundColor: "rgba(147, 192, 12, 1)",
          pointHoverBorderColor: "rgba(147, 192, 12, 1)",
        },
        {
          backgroundColor: "rgba(72, 192, 12, 0.2)",
          borderColor: "rgba(72, 192, 12, 1)",
          pointBackgroundColor: "rgba(72, 192, 12, 1)",
          pointHoverBorderColor: "rgba(72, 192, 12, 1)",
        },
        {
          backgroundColor: "rgba(12, 192, 27, 0.2)",
          borderColor: "rgba(12, 192, 27, 1)",
          pointBackgroundColor: "rgba(12, 192, 27, 1)",
          pointHoverBorderColor: "rgba(12, 192, 27, 1)",
        },
        {
          backgroundColor: "rgba(12, 192, 101, 0.2)",
          borderColor: "rgba(12, 192, 101, 1)",
          pointBackgroundColor: "rgba(12, 192, 101, 1)",
          pointHoverBorderColor: "rgba(12, 192, 101, 1)",
        },
        {
          backgroundColor: "rgba(12, 192, 177, 0.2)",
          borderColor: "rgba(12, 192, 177, 1)",
          pointBackgroundColor: "rgba(12, 192, 177, 1)",
          pointHoverBorderColor: "rgba(12, 192, 177, 1)",
        },
        {
          backgroundColor: "rgba(12, 132, 192, 0.2)",
          borderColor: "rgba(12, 132, 192, 1)",
          pointBackgroundColor: "rgba(12, 132, 192, 1)",
          pointHoverBorderColor: "rgba(12, 132, 192, 1)",
        },
        {
          backgroundColor: "rgba(15, 71, 240, 0.2)",
          borderColor: "rgba(15, 71, 240, 1)",
          pointBackgroundColor: "rgba(15, 71, 240, 1)",
          pointHoverBorderColor: "rgba(15, 71, 240, 1)",
        },
      ],
      selectedTab: "Performance",
      periods: [
        {
          Value: "Start",
          Month: "Sept",
          Year: "2021",
        },
        {
          Value: "3.8",
          Month: "Dec",
          Year: "2021",
        },
        {
          Value: "4.0",
          Month: "Aug",
          Year: "2022",
        },
        {
          Value: "4.5",
          Month: "Dec",
          Year: "2022",
        },
        {
          Value: "4.4",
          Month: "Feb",
          Year: "2023",
        },
        {
          Value: "4.6",
          Month: "Feb",
          Year: "2024",
        },
        {
          Value: "4.8",
          Month: "Feb",
          Year: "2025",
        },
        {
          Value: "4.9",
          Month: "Feb",
          Year: "2026",
        },
      ],
      categories: [],
      categoriesOrderByScoreAsc: true,
      selectedCategories: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Score",
            backgroundColor: "#f87979",
            data: [5, 4, 3, 2, 1],
          },
        ],
      },
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },

        scale: {
          font: {
            size: 12,
          },
        },
      },
    };
  },
  computed: {
    categoriesOrdered() {
      const sortBy = this.categoriesOrderByScoreAsc ? "reverse" : "sort";
      return [...this.selectedCategories][sortBy]((a, b) => {
        return a.AverageScore - b.AverageScore;
      });
    },
    chartDataSimplified() {
      return {
        labels: this.chartData.labels,
        datasets: this.chartData.datasets,
      };
    },
  },
  methods: {
    SetActiveEvaluationsCount(evals, type) {
      const concaLists = evals.map((item) => item.Evaluations).flat(1);
      this.evaluationsCount[type] = concaLists.length;
    },
    CalculatePercentageFromValue(value) {
      const min = 0;
      const max = 5;
      return `${((value - min) * 100) / (max - min)}`;
    },
    ChangeTab(value) {
      this.selectedTab = value;
      switch (value) {
        case "Performance":
          this.GetCategories();
          break;
        case "Evaluations":
          break;
        default:
          break;
      }
    },
    GetImgUrl(image) {
      if (image == null) {
        var images = require.context("@/AttachmentDirectory/", false, /\.png$/);
        return images("./no_avatar_ts.png");
      }
      return "data:image/jpeg;base64," + image;
    },

    ShowFirstWordFromUserName: function (userName) {
      return userName.substring(0, userName.indexOf(" "));
    },

    GetEmployeesCompare: async function (categoriesId) {
      store.dispatch("changeLoaderStatus", true);
      var compareFilter = {
        CompareBy: [174],
        CategoriesId: categoriesId,
      };
      return await this.$axios
        .post("api/Compare/getEmployeesComapare", compareFilter, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          store.dispatch("changeLoaderStatus", false);
          const test = response.data;
          return test.map((item, index) => ({ ...item, Period: `January 202${index}` }));
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
          return [];
        });
    },
    async ChooseCategories(selectedCategory, isChoosing) {
      if (isChoosing) {
        const categoryAlreadyAdded = !!this.selectedCategories.find((item) => item.Id === selectedCategory.Id);
        if (!categoryAlreadyAdded) {
          this.selectedCategories.push(selectedCategory);
        }
      } else {
        this.selectedCategories = this.selectedCategories.filter((category) => category.Id !== selectedCategory.Id);
      }
      const compare = await this.GetEmployeesCompare(this.selectedCategories.map((item) => item.Id));
      this.PopulateChartWithContent(compare);
    },
    PopulateChartWithContent: async function (result) {
      function generateList(length) {
        const result = [];
        for (let i = 0; i < length; i++) {
          const randomNumber = Math.floor(Math.random() * (length + 1));
          result.push(randomNumber);
        }
        return result;
      }

      this.chartData = {};
      this.chartData = {
        labels: result.map((x) => {
          return x.Period;
        }),
        datasets: this.selectedCategories.map((x) => {
          return {
            label: x.Name,
            backgroundColor: x.borderColor,
            borderColor: x.borderColor,
            data: generateList(result.length), //this is for visual testing because i got no data to display =[
            // data: result
            //   .filter((y) => y.CategoryId == x.Id)
            //   .map((y) => {
            //     return y.Avarage;
            //   }),
          };
        }),
      };
    },
    GetCategories: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Compare/getTopCategoriesForCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.categories = response.data;
          this.categories = this.categories.map((category, index) => ({ ...category, AverageScore: index + 1, ...this.chartColors[index] }));
          console.log(this.categories);
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetUserDetailsForEdit() {
      store.dispatch("changeLoaderStatus", true);
      this.$axios
        .get("api/Employee/getEmployeeById?employeeId=" + this.$store.getUser?.EmployeeId, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.userDetailsForEdit = response.data;
          if (this.userDetailsForEdit.Image) {
            this.userDetailsForEdit.urlFile = "data:image/jpeg;base64," + this.userDetailsForEdit.Image;
          }
          $("#user-profile-edit-details-modal").modal("show");
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    EditEmployee: async function (employeeDetails) {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .put("api/Employee/updateEmployee", employeeDetails, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.GetEmployees();
          store.dispatch("toastNotify", {
            Type: "success",
            Title: response.data,
          });
          $("#user-profile-edit-details-modal").modal("hide");
          this.$refs.editEmployeeRef.ClearModal();
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  created() {
    this.ChangeTab("Performance");
  },
};
</script>
<style scoped>
.evaluations-tab-number {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #adb5bd;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.line-chart-canvas {
  margin-top: 24px;
  margin-bottom: 43px;
}
.line-chart-canvas canvas {
  width: 100% !important;
  height: 100% !important;
}

.btn-remove {
  background-color: #adb5bd;
  border-radius: 2px;
  height: 16px;
  width: 16px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto !important;
}
.btn-remove i {
  color: white;
  font-size: 12px;
  padding-right: 0 !important;
}

.categories-legend .border-legend {
  width: 24px;
  height: 6px;
  border-radius: 50px;
}
.categories-legend .name {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: #495057;
}

.top-categories .score {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: #6c757d;
}
.top-categories .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #6c757d;
}

.progress {
  margin-top: 6px;
  height: 6px;
}
.order-asc {
  transform: rotate(180deg);
}

.top-categories-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  color: #6c757d;
}

.tab-title {
  font-size: 24px;
  color: #6c757d;
  font-weight: 500;
}

.period-content .dot::after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4dc4b3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.period-content .dot {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  top: -7px;
  border: 1px solid #4dc4b3;
}
.period-content .value {
  position: absolute;
  color: #6c757d;
  left: -5px;
  right: 0;
  margin: auto;
  top: -40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: center;
}
.period-content .date {
  position: absolute;
  left: -10px;
  right: 0;
  margin: auto;
  top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
  display: grid;
  color: #6c757d;
}
.period-content {
  position: relative;
}

.line {
  height: 2px;
  background: #ced4da;
}
.green.line {
  background: #4dc4b3;
}

.current-score {
  color: #188a94;
  display: flex;
  align-items: center;
}
.current-score .value {
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
}
.current-score .text {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-transform: uppercase;
  margin-left: 8px;
}

.part-of {
  margin-left: 12px;
}
.part-of .value {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: #6c757d;
}
.part-of .label {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-transform: uppercase;
  color: #adb5bd;
  display: block;
}

.card .user-email {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #6c757d;
  margin-top: 4px;
}
.card .user-name {
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  color: #188a94;
}

.card .user-img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 32px;
  margin-top: -60px;
}
.main.card-body {
  width: calc(100% - 153px);
  background-color: white;
  border-radius: 16px;
  height: 100%;
  padding: 24px 0px;
  z-index: 1;
}
.edit.card-body {
  position: absolute;
  right: 0;
  background: linear-gradient(87.6deg, #4dc4b3 0%, #209b8a 100%);
  width: 173px;
  border-radius: 16px;
  z-index: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: white;
  display: flex;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
.edit.card-body:hover {
  background: linear-gradient(87.6deg, #31bda9 0%, #077565 100%);
}

.main.card {
  border-radius: 16px;
  box-shadow: 0px 10px 30px 0px #49505714;
  background-color: transparent;
  min-height: 93px;
  margin-top: 55px;
}
.page-tabs {
  margin-bottom: 32px;
}
.page-tabs h3 {
  text-align: center;
  border-bottom: 2px solid #d9d9d9;
}
.page-tabs .active {
  border-bottom: 2px solid #188a94 !important;
  color: #188a94 !important;
}
</style>
