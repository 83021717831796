<template>
  <div class="modal fade" id="edit-evaluation-modal" tabindex="-1" aria-labelledby="edit-evaluation-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <Form v-slot="{ errors }" @submit="Save" ref="editEvaluationForm">
          <div class="modal-header">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 class="modal-title" id="edit-evaluation-modal-label">Edit evaluation</h2>
              </div>
              <div class="col text-end"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            </div>
          </div>

          <div class="modal-body">

            <h3>Evaluation details</h3>
            <div class="row mt-3 mb-3">
              <div class="col">
                <div class="form-group">
                  <label class="label">Name</label>
                  <Field type=" text" name="evaluationName" v-slot="{ field }" rules="required|min: 3|max: 200" placeholder="Evaluation name" v-model="evaluationDetails.Name">
                    <textarea v-bind="field" class="form-control" :class="{ 'border-danger': errors.evaluationName }" id="evaluation-name-textarea" rows="1" placeholder="Evaluation name"></textarea>
                  </Field>
                  <small v-if="errors.evaluationName" class="fv-plugins-message-container invalid-feedback">Title requires minimum 3 and maximum 200 characters.</small>
                </div>
              </div>

              <div class="col">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form-label">Score</label>
                      <Field type="number" name="maxScore" class="form-control form-control-solid ps-2" placeholder="Enter the max score..." v-model="evaluationDetails.MaxScore" rules="required|between: 1,10" />
                      <small v-if="errors.maxScore" class="fv-plugins-message-container invalid-feedback">Max score must be between 1 and 10</small>
                    </div>
                  </div>
                  <div v-if="selectedScope && selectedScope.Id != 1 && selectedScope.Id != 8" class="col">
                    <label class="form-label">Self assessment</label>
                    <button type="button" class="btn btn-white border w-100" v-on:click="evaluationDetails.SelfAssessment = !evaluationDetails.SelfAssessment">
                      <i class="far fa-2x w-auto" :class="[evaluationDetails.SelfAssessment ? 'fa-square-check' : 'fa-square']" style="font-size: 20px"></i>
                      For {{ selectedScope.Scope }}
                    </button>
                  </div>
                  <div class="col"
                    v-if="selectedScope && selectedScope.Id != 2 && selectedScope.Id != 4 && selectedScope.Id != 6">
                    <label class="form-label">Anonymous</label>
                    <button type="button" class="btn btn-white border w-100"
                      v-on:click="evaluationDetails.IsAnonim = !evaluationDetails.IsAnonim">
                      <i class="far fa-2x w-auto" :class="[evaluationDetails.IsAnonim ? 'fa-square-check' : 'fa-square']"
                        style="font-size: 20px"></i>
                        Anonymous
                    </button>
                  </div>
                </div>
              </div>

              <!-- <div class="col">
                <div class="form-group">
                  <label class="label">Description</label>
                  <textarea v-model="evaluationDetails.Description" class="form-control" rows="2" placeholder="Evaluation description"></textarea>
                </div>
              </div> -->
            </div>

            <div class="row">
              <div class="col">
                <h3 class="m-0">Active period</h3>
                <small>The period in which the assessment is open</small>
                <div class="bg-gray-100 border p-3 rounded mt-3">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label>Start date</label>
                        <div v-if="evaluationDetails.GroupEvalNr == 1" class="input-group">

                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Field v-model="evaluationDetails.StartInterval" name="startInterval" v-slot="{ field }" :rules="{ max_date: [evaluationDetails.StartInterval, evaluationDetails.EndInterval] }">
                            <Datepicker v-bind="field" v-model="evaluationDetails.StartInterval" class="form-control custom-datepicker" placeholder="Select start of the interval" :enableTimePicker="false" autoApply name="Start date" format="dd-MM-yyyy"></Datepicker>
                          </Field>

                        </div>
                        <div v-else class="form-control fs-7" style="background: #f6f8fa!important;">
                          {{ $utils.formatDate(evaluationDetails.StartInterval) }}
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="form-group">
                        <label>End date</label>
                        <div v-if="evaluationDetails.GroupEvalNr == 1" class="input-group">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Field name="endInterval" v-slot="{ field }" :rules="{ min_date: [new Date(), evaluationDetails.EndInterval] }">
                            <Datepicker v-bind="field" v-model="evaluationDetails.EndInterval" class="form-control custom-datepicker" placeholder="Select end of the interval" :enableTimePicker="false" autoApply name="End date" format="dd-MM-yyyy"></Datepicker>
                          </Field>

                        </div>
                        <div v-else class="form-control fs-7" style="background: #f6f8fa!important;">
                          {{ $utils.formatDate(evaluationDetails.EndInterval) }}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <small v-if="errors.startInterval" class="fv-plugins-message-container invalid-feedback">{{ errors.startInterval }}</small>
                  </div>
                  <div class="col">
                    <small v-if="errors.endInterval" class="fv-plugins-message-container invalid-feedback">{{ errors.endInterval }} </small>
                  </div>
                </div>
              </div>

              <div class="col">
                <h3 class="m-0">Evaluated period</h3>
                <small>The period for which the assessment is made</small>
                <div class="bg-gray-100 border p-3 rounded mt-3">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label class="label">Start date</label>
                        <div class="input-group mb-5">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Field v-model="evaluationDetails.StartDate" name="startDate" v-slot="{ field }" rules="required">
                            <Datepicker v-bind="field" v-model="evaluationDetails.StartDate" class="form-control form-control-solid ps-2 custom-datepicker" placeholder="Select start date" :enableTimePicker="false" autoApply format="dd-MM-yyyy"></Datepicker>
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label class="label">End Date</label>
                        <div class="input-group mb-5">
                          <span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
                          <Field name="endDate" v-slot="{ field }" :rules="{ min_date: [evaluationDetails.StartDate, evaluationDetails.EndDate] }">
                            <Datepicker v-bind="field" v-model="evaluationDetails.EndDate" class="form-control custom-datepicker" placeholder="Select end date" :enableTimePicker="false" autoApply format="dd-MM-yyyy"></Datepicker>
                          </Field>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <small v-if="errors.startDate" class="fv-plugins-message-container invalid-feedback">Start date is required.</small>
                  </div>
                  <div class="col">
                    <small v-if="errors.endDate" class="fv-plugins-message-container invalid-feedback">{{ errors.endDate }} </small>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- <div v-if="evaluationDetails.GroupEvalNr == 1" class="col"> -->
              <!-- <div v-if="evaluationDetails.GroupEvalNr == 1" class="col"> -->
              <!-- </div> -->
            </div>

            <hr class="mt-5 mb-10" />

            <div class="row">
              <div class="col-3">
                <h3 class="text-dark-green mb-5">1. Evaluation scope</h3>
                <div class="row">
                  <div v-bind:key="scopeIndex" v-for="(scope, scopeIndex) in scopeCategories" v-on:click="SelectedScopeCategory(scope)" class="col-xl-12 text-center mb-5">
                    <div class="card shadow-sm" :class="{ 'selected-scope ': evaluationDetails.ScopeId == scope.Id }">
                      <div class="card-body">
                        <img class="mb-4" v-bind:src="require('@/assets/images/scopes-icons/' + scope.Name + '.png')" />
                        <h5>{{ scope.Name }}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="evaluationDetails.ScopeId" class="col-3 ms-10 me-10">
                <h3 class="text-dark-green mb-5">2. Evaluation target</h3>
                <div class="row">
                  <div v-bind:key="targetIndex" v-for="(target, targetIndex) in scopes" v-on:click="SelectedScope(target)" class="col-12 text-center mb-5">
                    <div class="card shadow-sm" :class="{ 'selected-scope ': evaluationDetails.TargetId == target.Id }">
                      <div class="card-body">
                        <img class="mb-4" v-bind:src="require('@/assets/images/scopes-icons/' + target.Scope + '.png')" />
                        <h5>{{ target.Scope }}</h5>
                        <small>{{ target.Description }}.</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col" v-if="selectedScope != null && evaluationDetails.ScopeId != 10 && evaluationDetails.ScopeId">
                <h3 class="text-dark-green mb-5">3. Evaluation participants</h3>
                <div class="input-group rounded">
                  <span class="input-group-text border-0"><i class="fa-solid fa-search fs-18px"></i></span>
                  <input v-model="searchText" v-on:keyup.enter="PrepareScope()" type="text" class="form-control" id="search" placeholder="Search" />
                </div>

                <div class="scroll-table">
                  <table class="table table-row-dashed mt-10">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th v-bind:key="index" v-for="(th, index) in table.Header">
                          <h5>{{ th }}</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-bind:key="index" v-for="(item, index) in table.Body">
                        <td>
                          <!-- {{ selectedScope }} -->
                          <span v-if="item.Second || (item.List && item.List.length > 0) || selectedScope.Id == 8" v-on:click="evaluationDetails.EvaluationForId = item.Id">
                            <i v-if="evaluationDetails.EvaluationForId == item.Id" class="fa-solid fa-square-check fa-2x text-green"></i>
                            <i v-else class="fa-duotone fa-square fa-2x"></i>
                          </span>
                          <i v-else title="No leader" class="fa-duotone fa-2x fa-square-info text-gray-400"></i>
                        </td>
                        <td>
                          <h6 v-if="item.First">{{ item.First }}</h6>
                          <h6 v-else>
                            <span v-bind:key="index" v-for="(listItem, index) in item.List">{{ listItem }} <span v-if="index > item.List.length">,</span></span>
                          </h6>
                        </td>
                        <td>
                          <span v-if="item.Second">{{ item.Second }}</span>
                          <span v-else v-bind:key="index" v-for="(listItem, index) in item.List">{{ listItem }}
                            <br />
                          </span>
                          <span v-if="!item.Second">No leader</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-stack">
            <button type="button" data-bs-dismiss="modal" class="border btn btn-white">Close</button>
            <button type="submit" :disabled="!Validate()" class="btn btn-gradient-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import { Field, Form } from "vee-validate";

export default {
  name: "edit-evaluation-modal-component",
  components: { Field, Form },
  props: ["evaluationDetails"],

  watch: {
    evaluationDetails: function (newVal, oldVal) {
      if (newVal.ScopeId != null) {
        this.GetScopesByScopeCategory(newVal.ScopeId);
        this.selectedScope = {};
        this.selectedScope.Id = newVal.TargetId;
        this.PrepareScope();
      }
    },
  },
  data() {
    return {
      searchText: "",
      itemsOfChosedScope: [],
      selectedScopeCategory: {},
      selectedScope: null,
      scopeCategories: [],
      scopes: [],
      table: {
        Header: [],
        Body: [],
      },
      endInterval: null,
      startInterval: null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    Save: async function () {
      await this.$emit("update");
    },

    Validate: function () {
      if (this.evaluationDetails.ScopeId == 10) {
        return true;
      }

      if (this.evaluationDetails.EvaluationForId) {
        return true;
      }

      return false;
    },
    GetTeamLeaders: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/Team/getTeamsDetailsByRole?searchText=" + this.searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.itemsOfChosedScope = response.data;
          this.table.Header = ["Team", "Team Leader"];

          this.table.Body = response.data.map((team) => ({
            Id: team.Id,
            First: team.Name,
            Second: team.LeaderName,
          }));
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetProjects: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get(
          "api/Project/getProjectsDetailsByRole?searchText=" + this.searchText,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.itemsOfChosedScope = response.data;
          this.table.Header = ["Project", "Project manager"];
          this.table.Body = response.data.map((team) => ({
            Id: team.ProjectId,
            First: team.Name,
            Second: team.ManagerName,
          }));
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetProjectManagers: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get(
          "api/Project/getProjectsDetailsByRole?searchText=" + this.searchText,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.itemsOfChosedScope = response.data;

          this.table.Header = ["Project", "Project Manager"];
          this.table.Body = response.data.map((team) => ({
            Id: team.ProjectId,
            First: team.Name,
            Second: team.ManagerName,
          }));

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetGroups: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get(
          "api/EvaluationGroup/getEvaluationGroupByRole?searchText=" +
            this.searchText,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.itemsOfChosedScope = response.data;
          this.table.Header = ["Group", "Leaders"];
          this.table.Body = response.data.map((team) => ({
            Id: team.Id,
            First: team.Name,
            List: team.Leaders,
          }));
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetGroupsLeaders: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get(
          "api/EvaluationGroup/getAllGroupsLeaders?searchText=" +
            this.searchText,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.itemsOfChosedScope = response.data;

          this.table.Header = ["Group Leaders", "Name"];
          this.table.Body = response.data.map((team) => ({
            Id: team.Id,
            First: team.Leaders,
            Second: team.Name,
          }));

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetTeams: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/Team/getTeamsDetailsByRole?searchText=" + this.searchText, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.itemsOfChosedScope = response.data;

          this.table.Header = ["Team", "Team Leader"];

          this.table.Body = response.data.map((team) => ({
            Id: team.Id,
            First: team.Name,
            Second: team.LeaderName,
          }));

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    SelectedScope: function (scope) {
      this.evaluationDetails.TargetId = scope.Id;
      this.selectedScope = scope;
      this.itemsOfChosedScope = [];
      this.evaluationDetails.EvaluationForId = null;
      this.PrepareScope();
    },

    PrepareScope: function () {
      switch (this.selectedScope.Id) {
        case 2:
          this.GetTeams();
          break;
        case 3:
          this.GetTeamLeaders();
          break;
        case 4:
          this.GetProjects();
          break;
        case 5:
          this.GetProjectManagers();
          break;
        case 6:
        case 7:
        case 8:
          this.GetGroups();
          break;
        default:
          break;
      }
    },

    SelectedScopeCategory: function (scope) {
      this.evaluationDetails.ScopeId = scope.Id;
      this.selectedScope = null;
      this.evaluationDetails.EvaluationForId = null;
      this.itemsOfChosedScope = [];
      this.GetScopesByScopeCategory(scope.Id);
    },

    GetScopesCategories: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get("api/EvaluationPlan/getScopeCategoriesByRole?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.scopeCategories = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetScopesByScopeCategory: async function (scopeCategoryId) {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get(
          "api/EvaluationTemplate/getScopesByScopeCategory?scopeCategoryId=" +
            scopeCategoryId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.scopes = response.data;

          if (this.scopes.length > 0 && this.scopes[0].Id == 1) {
            this.evaluationDetails.TargetId = this.scopes[0].Id;
          }

          if (this.evaluationDetails.TargetId) {
            this.scopes.forEach((scope) => {
              if (scope.Id == this.evaluationDetails.TargetId) {
                this.selectedScope = scope;
              }
            });
          }
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  created: function () {
    this.GetScopesCategories();
  },
};
</script>

<style scoped>
.fa-square:hover:before {
  content: "\f14a";
  color: #ddd !important;
  transition: 0.1s;
}

.card-body img {
  width: 30px;
}

.card {
  cursor: pointer;
  border: 1px solid white;
}

.scroll-table {
  max-height: 490px;
  overflow: auto;
}

.card:hover {
  border: 1px solid #4dc4b3;
  background: #f6fcfb;
}

.fa-square,
.fa-square-check {
  cursor: pointer;
}

.selected-scope {
  border: 1px solid #4dc4b3;
  background: #f6fcfb;
}

.card-body {
  padding: 2rem 5px;
}
</style>
