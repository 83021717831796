<template>
  <div class="bg"></div>

  <div class="container-xl">
    <div class="section-title text-center">
      Intră în contul tău <br />
      pentru a folosi platforma ExperEval
    </div>

    <div class="bg-form-white m-auto" style="border-radius: 12px">
      <div class="login-form card h-100">
        <div class="card-body">
          <div class="text-center">
            <img src="/img/logo.62be563a.png" class="mb-10 mw-200px" />
            <h4 class="mb-8">Login as:</h4>
          </div>
          <div class="row">
            <div :class="[index === 0 ? 'col-12' : 'col-sm-6 mb-3 mb-sm-0']" v-for="(type, index) in loginTypes" :key="index">
              <div class="card border border-1 text-center mb-7 shadow-hover pointer" @click="SelectUser(type.Name)">
                <div class="card-body">
                  <h5>{{ type.Name }}</h5>
                  <small class="text-black">{{ type.Description }} </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-footer">
      <div class="title">Autentificare</div>
      <p class="description">
        Pentru a te loga în contul tau de Evaluation, este nevoie să introduci email-ul furnizat de către administrator și parola creată de tine. În cazul în care nu este posibilă logarea, apasă “Ai
        uitat parola?” sau contactează un expert.
      </p>
    </div>
  </div>
  <LandingPageFooter />
</template>

<script>
import LandingPageFooter from "@/components/Footer/LandingPageFooter.vue";

import auth from "@/utils/auth.js";
import moment from "moment";
import store from "@/utils/vuex.js";

export default {
  name: "LoginView",
  components: { LandingPageFooter },
  data() {
    return {
      loginTypes: [
        {
          Name: "Administrator",
          Description: "Control on your organization",
        },
        {
          Name: "Team Leader",
          Description: "Evaluate your teams",
        },
        {
          Name: "Project manager",
          Description: "Evaluate your project members",
        },
        {
          Name: "Group leader",
          Description: "Evaluate your group members",
        },
        {
          Name: "Employee",
          Description: "Make your self-assessment",
        },
      ],
      user: {
        UserName: null,
        Password: null,
      },
    };
  },
  methods: {
    SelectUser: function (type) {
      switch (type) {
        case "Administrator":
          this.user.UserName = "admin@expertware.net";
          break;
        case "Team Leader":
          this.user.UserName = "teamLeader-1@expertware.net";
          break;
        case "Project manager":
          this.user.UserName = "projectManager-1@expertware.net";
          break;
        case "Group leader":
          this.user.UserName = "groupLeader@expertware.net";
          break;
        case "Employee":
          this.user.UserName = "employee@expertware.net";
          break;
      }
      this.user.Password = "Expert123";
      this.Login();
    },
    Login: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .post("api/Auth/login", this.user)
        .then(async (response) => {
          const token = {
            Value: response.data.token,
            Expiration: moment(response.data.expiration).format("YYYY-MM-DDTHH:mm:ss"),
          };
          auth.setJwtToken(token);
          auth.setRefreshToken(response.data.RefreshToken);
          store.dispatch("fetchUsers").then(() => {
            this.$router.push("/My-evaluations/Active");
          });
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-empty
          if (error.response.data.includes("User don't found") || error.response.data.includes("Wrong password")) {
          } else
            store.dispatch("toastNotify", {
              Type: "error",
              Title: "Something went wrong!",
            });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
};
</script>

<style scoped>
.forgot-password {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1400px) {
  .bg-form-white {
    max-width: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .forgot-password {
    height: 70px;
  }
}
@media only screen and (min-width: 1200px) {
  .forgot-password {
    height: 133px;
  }
}

.login.card input::placeholder {
  font-size: 16px !important;
}

.title-section {
  font-size: 40px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0em;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0em;
  margin-top: 75px;
}

/*GET IN TOUCH*/
svg {
  color: #26a190;
}

.login-form span {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s !important;
}

.login-form span i {
  color: rgba(77, 196, 179, 1) !important;
}

.login-form a:hover span {
  background-color: #016972 !important;
}

.login-form a:hover svg {
  color: white !important;
}

.login-form .find-us-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.login-form .form-control {
  background-color: transparent;
  color: white;
  font-size: 16px !important;
  line-height: 21px;
}

.login-form input::placeholder {
  color: white !important;
  font-size: 16px !important;
}

.login-form .title {
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  text-align: center;
}

.login-form.card {
  border-radius: 0px 12px 12px 0px;
  background-color: transparent;
  color: white;
}
label {
  color: rgba(108, 117, 125, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.section-title {
  margin-top: 74px;
  color: rgba(24, 138, 148, 1);
  font-size: 32px;
  margin-bottom: 67px;
}

.form-control {
  padding: 16px;
}

.login .title {
  color: rgba(52, 58, 64, 1);
  font-size: 32px;
  font-weight: 400;
  line-height: 37px;
}

.login.card {
  border-radius: 12px;
  text-align: left;
}

.bg {
  background-image: url("../../assets/images/landing-page/square-get-in-touch.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  display: inline-block;
  width: 100%;
  bottom: 0;
  height: 100vh;
  right: 0;
  left: 0;
  z-index: -1;
}

.section-footer {
  margin-top: 80px;
  margin-bottom: 80px;
}

.section-footer .title {
  color: rgba(24, 138, 148, 1);
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 24px;
}

.section-footer .description {
  color: rgba(108, 117, 125, 1);
  font-size: 16px;
  font-weight: 300;
}

.input-group {
  border-radius: 0.475rem !important;
}
.input-group .form-control {
  padding-left: 16px !important;
  margin-left: 0 !important;
  border-top-left-radius: 0.475rem !important;
  border-bottom-left-radius: 0.475rem !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input-group-text {
  border: 1px solid #e7e7e7 !important;
  border-left: 0px !important;
}

/* change forms bg */
.bg-form-green {
  background: linear-gradient(87.6deg, #4dc4b3 0%, #209b8a 100%);
}

.bg-form-green .login-form label {
  color: white;
}

.bg-form-white .login-form .title {
  color: rgba(52, 58, 64, 1);
}

.bg-form-white .login-form label {
  color: rgba(108, 117, 125, 1);
}

.bg-form-white .login .title {
  color: white;
}

.bg-form-white {
  background: white;
}

.bg-form-white .login.card {
  background: linear-gradient(87.6deg, #4dc4b3 0%, #209b8a 100%);
}

.bg-form-white .login.card label {
  color: white;
}

.bg-form-white .login-form .form-control {
  color: rgba(108, 117, 125, 1);
}

.bg-form-white .login-form input::placeholder {
  color: rgba(108, 117, 125, 1) !important;
}

.bg-form-white .forgot-password .text {
  color: rgba(108, 117, 125, 1);
}

.bg-form-white .forgot-password .text:hover {
  color: black;
}

.forgot-password .text {
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s;
  width: 100%;
  text-align: center;
}
</style>
